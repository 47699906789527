import React from "react";

const Process = (props) => {

    return (<>
        <div className="process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay={props.Delay}>
            <div className="inner-box">
                <div className="icon-box">
                    <figure className="image"><img src={props.Image} alt="" />
                    </figure>
                    <span className="count">{props.Count}</span>
                </div>
                <div className="content-box">
                    <h6 className="title">{props.Title}</h6>
                    <div className="text text_16">{props.Description}</div>
                </div>
            </div>
        </div>
    </>)

};

export default Process;