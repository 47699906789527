import React, { useState, useEffect } from "react";
import "../../UI-style/Form.css"
import FromAddress from "../Form";
import { CalendarComp } from "./Calendar";
import BackgroundImg from "../../Images/background/1.webp"
import swal from 'sweetalert';

const FormComponent = (props) => {

    const [progress, setProgress] = useState(1);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [email, setEmail] = useState("");
    const [currentSuburb, setCurrentSuburb] = useState("");
    const [currentStreet, setCurrentStreet] = useState("");
    const [currentParkingDistance, setCurrentParkingDistance] = useState("");
    const [currentStairs, setCurrentStairs] = useState("");
    const [currentBuilding, setCurrentBuilding] = useState("");
    const [currentLiftPermission, setCurrentLiftingPermission] = useState("");
    const [currentDataSubmit, setCurrentDataSubmit] = useState(false);

    const [targetSuburb, setTargetSuburb] = useState("");
    const [targetStreet, setTargetStreet] = useState("");
    const [targetParkingDistance, setTargetParkingDistance] = useState("");
    const [targetStairs, setTargetStairs] = useState("");
    const [targetBuilding, setTargetBuilding] = useState("");
    const [targetLiftPermission, setTargetLiftingPermission] = useState("");
    const [targetDataSubmit, setTargetDataSubmit] = useState(false);

    const [reset, setReset] = useState(false);
    const phoneRegex = /^\d{10}$/
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    useEffect(() => {
        if (currentDataSubmit) {
            if (
                currentSuburb &&
                currentStreet &&
                currentParkingDistance &&
                currentStairs &&
                currentBuilding &&
                currentLiftPermission &&
                phoneRegex.test(phoneNum) &&
                firstName &&
                lastName &&
                emailRegex.test(email)
            ) {
                setProgress(progress + 1);
                localStorage.setItem('currentAddress', JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    phoneNum: phoneNum,
                    email: email,
                    suburb: currentSuburb,
                    street: currentStreet,
                    parkingDistance: currentParkingDistance,
                    stairs: currentStairs,
                    building: currentBuilding,
                    liftPermission: currentLiftPermission,
                }));
            } else {
                swal("Error", "Please Fill all above feilds with valid information", "error");
            }
            setTimeout(() => {
                setCurrentDataSubmit(false);
            }, 700)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDataSubmit]);

    useEffect(() => {
        if (targetDataSubmit) {
            if (
                targetSuburb &&
                targetStreet &&
                targetParkingDistance &&
                targetStairs &&
                targetBuilding &&
                targetLiftPermission
            ) {
                setProgress(progress + 1);
                localStorage.setItem("targetAddress", JSON.stringify({
                    suburb: targetSuburb,
                    street: targetStreet,
                    parkingDistance: targetParkingDistance,
                    stairs: targetStairs,
                    building: targetBuilding,
                    liftPermission: targetLiftPermission,
                }));
            } else {
                swal("Error", "Please Fill all above feilds", "error");
            }
            setTimeout(() => {
                setTargetDataSubmit(false);
            }, 700)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetDataSubmit]);

    const handleFromPage = () => {
        if (progress > 1) {
            setProgress(1);
        }
    }

    const handleToPage = () => {
        if (progress > 2 || localStorage.getItem('currentAddress')) {
            setProgress(2);
        } else {
            swal("Error", "Please fill the current address first", "error");
        }
    }

    const handleDatePage = () => {
        if (localStorage.getItem('targetAddress')) {
            setProgress(3);
        } else {
            if (progress === 1) {
                swal("Error", "Please fill the current address first", "error");
            } else {
                swal("Error", "Please fill the target address first", "error");
            }
        }
    }

    useEffect(() => {
        if (reset) {
            setCurrentSuburb('');
            setCurrentStreet('');
            setCurrentParkingDistance('');
            setCurrentStairs('');
            setCurrentBuilding('');
            setCurrentLiftingPermission('');
            setFirstName('');
            setLastName('');
            setPhoneNum('');
            setEmail('');

            setTargetSuburb('');
            setTargetStreet('');
            setTargetParkingDistance('');
            setTargetStairs('');
            setTargetBuilding('');
            setTargetLiftingPermission('');

            setReset(false);
        }
    }, [reset])

    useEffect(() => {
        const storedProgress = localStorage.getItem('progress');
        const currentAddress = JSON.parse(localStorage.getItem("currentAddress"));
        const targetAddress = JSON.parse(localStorage.getItem("targetAddress"));

        if (storedProgress) {
            setProgress(+storedProgress);
        }
        if (currentAddress) {
            setFirstName(currentAddress.firstName);
            setLastName(currentAddress.lastName);
            setPhoneNum(currentAddress.phoneNum);
            setEmail(currentAddress.email);
            setCurrentSuburb(currentAddress.suburb);
            setCurrentStreet(currentAddress.street);
            setCurrentParkingDistance(currentAddress.parkingDistance);
            setCurrentStairs(currentAddress.stairs);
            setCurrentBuilding(currentAddress.building);
            setCurrentLiftingPermission(currentAddress.liftPermission);
        }
        if (targetAddress) {
            setTargetSuburb(targetAddress.suburb);
            setTargetStreet(targetAddress.street);
            setTargetParkingDistance(targetAddress.parkingDistance);
            setTargetStairs(targetAddress.stairs);
            setTargetBuilding(targetAddress.building);
            setTargetLiftingPermission(targetAddress.liftPermission);
        }
    }, []);

    useEffect(() => {
        if (progress !== 1) {
            localStorage.setItem('progress', progress);
        }
    }, [progress]);

    return (<>

        <div className={`row position-relative  ${props.custom_class}`}>
            <div className={`tabs-column col-lg-12 col-md-12 col-sm-12 ${props.padd} ${props.marginTop} `} data-wow-delay="300ms">
                <div className="inner-column">
                    <div className="bg bg-image z-index--1" style={{ backgroundImage: `url(${BackgroundImg})` }}></div>
                    <div className="contact-tabs tabs-box">
                        <div className="btn-box">
                            <div className="inner-column">
                                <ul className="tab-btns tab-buttons">
                                    <li className={`tab-btn ${progress === 1 ? "active-btn" : ''} ${progress > 1 ? "green-bg" : ''}`} onClick={handleFromPage} data-tab="#tab1">From</li>
                                    <li className={`tab-btn ${progress === 2 ? "active-btn" : ''} ${progress > 2 ? "green-bg" : ''}`} onClick={handleToPage} data-tab="#tab2">To</li>
                                    <li className={`tab-btn ${progress === 3 ? "active-btn" : ''}`} onClick={handleDatePage} data-tab="#tab3">Date Of Packing</li>
                                </ul>
                            </div>
                        </div>
                        <div className="tabs-content">
                            <div className="tab active-tab" id="tab1">
                                <div className="form-column">
                                    <div className="inner-column">
                                        <div className="contact-form">
                                            {progress === 1 && (
                                                <FromAddress
                                                    setFirstName={setFirstName}
                                                    setLastName={setLastName}
                                                    setPhoneNum={setPhoneNum}
                                                    setEmail={setEmail}
                                                    setSuburb={setCurrentSuburb}
                                                    setStreet={setCurrentStreet}
                                                    setParkingDistance={setCurrentParkingDistance}
                                                    setStairs={setCurrentStairs}
                                                    setBuilding={setCurrentBuilding}
                                                    setLiftingPermission={setCurrentLiftingPermission}
                                                    setProgress={setProgress}
                                                    setDataSubmit={setCurrentDataSubmit}
                                                    suburb={currentSuburb}
                                                    street={currentStreet}
                                                    parkingDistance={currentParkingDistance}
                                                    stairs={currentStairs}
                                                    building={currentBuilding}
                                                    liftingPermission={currentLiftPermission}
                                                    firstName={firstName}
                                                    lastName={lastName}
                                                    phoneNum={phoneNum}
                                                    email={email}
                                                    mt="mt-4"
                                                    progressTab
                                                />
                                            )}
                                            {progress === 2 && (
                                                <FromAddress
                                                    setSuburb={setTargetSuburb}
                                                    setStreet={setTargetStreet}
                                                    setParkingDistance={setTargetParkingDistance}
                                                    setStairs={setTargetStairs}
                                                    setBuilding={setTargetBuilding}
                                                    setLiftingPermission={
                                                        setTargetLiftingPermission
                                                    }
                                                    setProgress={setProgress}
                                                    setDataSubmit={setTargetDataSubmit}
                                                    suburb={targetSuburb}
                                                    street={targetStreet}
                                                    parkingDistance={targetParkingDistance}
                                                    stairs={targetStairs}
                                                    building={targetBuilding}
                                                    liftingPermission={targetLiftPermission}
                                                    mt="mt-4"
                                                />
                                            )}
                                            {progress === 3 && (
                                                <CalendarComp custom_width={props.Width} Center={props.cus_Center} DisplayClass={props.cus_DisplayClass} setProgress={setProgress} setReset={setReset} />
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    </>)
}

export default FormComponent;