import React from "react";
import ServiceDetail from "../../src/UI-Components/ServiceDetail";

const House = () => {

    return (<>

        <ServiceDetail
            Heading="House and Office Moving"

            OverviewP1="Expert in moving, DAV Transport has been in the moving business in North Island since 2015. We are committed to delivering high quality, local service. DAV provides local, national, international and commercial removals to customers. No matter the size or distance of your move, using DAV transport means your belongings reach their destination safely. The fact is, we’ve already helped more than a thousand people make stress-free transitions to their new homes and offices. We love being a part of those fresh starts and new journeys, and we’re just as excited to help you with yours."

            OverviewP2="We offer a stress-free service at an affordable price, providing you with a smooth move that minimizes disruptions. From selecting your ideal moving date and choosing from a range of add-on services, we offer everything you need for your move. We are glad to go along with you in your new venture of the move from the start until the end and take you to new spots easily and securely. Let’s begin!"

            Para1="DAV Transport believe all our customer requirements are different, that’s why we cater to our customers according to their requirement. DAV transport provides a range of extra services of packing, unpacking, assembly and disassembly. We have a robust process. Once you give us a call, we will start discussing your moving requirements, and we’ll design a personalized moving strategy"

            Para2="After that our skilled team arrives prepared, ensuring your belongings are packed and secured. on Your moving day we carried out your move with precision and keeping you informed throughout the process. At the end of your move, we cautiously place your items in your new house and offer unpacking assistance for seamless transition. " />

    </>)

};

export default House;