import React from "react";
import ServiceDetail from "./ServiceDetail";

const FragileFrieght = () => {

    return (<>
        <ServiceDetail

            Heading="Fragile Freight"

            OverviewP1="We offer cost-effective fragile delivery services, with a team specially trained to handle delicate items with utmost care. Whether for commercial or residential needs, DAV transport ensures efficient and high-quality delivery of fragile goods, maintaining the highest standard of safety and reliability."

            OverviewP2="DAV specialized in handling the fragile goods even if it’s a piece of art or electronic item we start the precise care from the beginning and we have the packaging material according to the goods requirement where the goods or consignment stay safe while delivery process finish. "

            // Para1="There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable."

            // Para2="There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable."
        
        />
    </>)

};

export default FragileFrieght;