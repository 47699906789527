import React from "react";
import "react-calendar/dist/Calendar.css";
import { useState } from "react";
import Calendar from "react-calendar";
import emailjs from "@emailjs/browser";
import swal from 'sweetalert';

export const CalendarComp = (props) => {
  const { setProgress, setReset } = props;
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const {
    REACT_APP_EMAIL_TEMPLATE_ID,
    REACT_APP_EMAIL_PUBLIC_KEY,
    REACT_APP_EMAIL_SERVICE_ID,
  } = process.env;

  const formattedDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  function isDateGreaterThanToday(givenDate) {
    let dateToCheck = new Date(givenDate);
    dateToCheck.setHours(0, 0, 0, 0);

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    return dateToCheck > today;
  }

  function toCapital(str) {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  const handleSubmit = (e) => {
    setLoading(true);
    e?.preventDefault();
    if (!isDateGreaterThanToday(date)) {
      swal("Error", "Please choose a date in the future.", "error");
      setLoading(false);
      return;
    }
    const currentAddress = JSON.parse(localStorage.getItem("currentAddress"));
    const targetAddress = JSON.parse(localStorage.getItem("targetAddress"));
    emailjs
      .send(
        REACT_APP_EMAIL_SERVICE_ID,
        REACT_APP_EMAIL_TEMPLATE_ID,
        {
          username: toCapital(currentAddress?.firstName) + " " + toCapital(currentAddress?.lastName),
          useremail: currentAddress?.email,
          reply_to: currentAddress?.email,
          phoneNum: currentAddress?.phoneNum,
          currentCity: currentAddress?.suburb,
          currentStreet: currentAddress?.street,
          currentBuilding: currentAddress?.building,
          currentParking: currentAddress?.parkingDistance,
          currentStairs: currentAddress?.stairs,
          currentUseLift: currentAddress?.liftPermission,
          targetCity: targetAddress?.suburb,
          targetStreet: targetAddress?.street,
          targetBuilding: targetAddress?.building,
          targetParking: targetAddress?.parkingDistance,
          targetStairs: targetAddress?.stairs,
          targetUseLift: targetAddress?.liftPermission,
          date: formattedDate(date),
        },
        {
          publicKey: REACT_APP_EMAIL_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          setLoading(false);
          swal("Done", "Successfully order placed. Thank You!", "success");
          setTimeout(() => {
            setProgress(1);
            localStorage.removeItem("currentAddress");
            localStorage.removeItem("targetAddress");
            localStorage.removeItem("progress");
            setReset(true);
          }, 2000);
        },
        (error) => {
          setLoading(false);
          swal("Error", "Unexpected error is there. Please try again", "error");
        }
      );
  };

  return (
    <div>
      <p className={props.Center}>
        <b className="form-label">Which date works best?</b>
      </p>
      <Calendar
        className={`${props.custom_width}`}
        onChange={(e) => {
          setDate(e);
        }}
        value={date}
      />
      <div className={`form-container my-3 custom_button mt-5 ${props.DisplayClass}`}>
        <div
          className="animate-3 block theme-btn btn-style-one"
          onClick={handleSubmit}
        >
          <span className="btn-title">{loading ? 'Loading...' : 'Submit'}</span>
        </div>
      </div>
    </div>
  );
};
