import React from "react";
import { Link } from "react-router-dom";

const AboutCarousel = (props) => {

    return (<>
        <div className="service-block-three">
            <div className="inner-box">
                <div className="bg-line"></div>
                <i className="icon">{props.Icon}</i>
                <h4 className="title">{props.Title}</h4>
                <div className="text text_16">{props.Description}</div>
                <Link to={props.PathLink} className="read-more"><i className="fa fa-plus"></i></Link>
            </div>
        </div>
    </>)

};

export default AboutCarousel;