import React from "react";
import ServiceDetail from "./ServiceDetail";

const LineHaulTruck = () => {

    return (<>
        <ServiceDetail
            OverviewP1="Supporting our existing retail customers and new customers, DAV Transport offer reliable weekly line haul services across New Zealand’s North Island. We ensure timely movement of your stock and customers’ orders to and from regions including Northland, Auckland, Waikato, Bay of Plenty, Gisborne, Taranaki, Manawatū-Whanganui, Hawke’s Bay, wellington. "

            Heading="Line Haul Trucking"

            OverviewP2="We provide an urgent delivery service as well in inter region. Whether you have a regular weekly delivery or have a one-off project, contact us today to learn how we can assist with your logistics needs."

            Para1="DAV Transport believe all our customer requirements are different, that’s why we cater to our customers according to their requirement. DAV transport provides a range of extra services of packing, unpacking, assembly and disassembly. We have a robust process. Once you give us a call, we will start discussing your moving requirements, and we’ll design a personalized moving strategy"

            Para2="After that our skilled team arrives prepared, ensuring your belongings are packed and secured. on Your moving day we carried out your move with precision and keeping you informed throughout the process. At the end of your move, we cautiously place your items in your new house and offer unpacking assistance for seamless transition. "
        />
    </>)

};

export default LineHaulTruck;