import React, { useState } from "react";
import logo from "../../DAVimage/davtransport-logo-CMYK.webp";
import { Link, Outlet, useLocation } from "react-router-dom";
import ButtonReusable from "./ButtonReusable";
import { RxHamburgerMenu } from "react-icons/rx";
import MobileNav from "./ModalNav";
import $ from "jquery";

const Header1 = () => {


    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const openMobileNav = () => {
        setIsMobileNavOpen(true);
    };

    const closeMobileNav = () => {
        setIsMobileNavOpen(false);
    };

    function headerStyle() {
        if ($('.main-header').length) {
            var windowpos = $(window).scrollTop();
            var siteHeader = $('.header-style-one');
            var scrollLink = $('.scroll-to-top');
            var sticky_header = $('.main-header .sticky-header');

            if (windowpos > 100) {
                sticky_header.addClass("fixed-header animated slideInDown");
                scrollLink.fadeIn(300);
            } else {
                sticky_header.removeClass("fixed-header animated slideInDown");
                scrollLink.fadeOut(300);
            }

            if (windowpos > 1) {
                siteHeader.addClass("fixed-header");
            }
            else {
                siteHeader.removeClass("fixed-header");
            }
        }
    }

    $(window).on('scroll', function () { headerStyle() });

    const location = useLocation();

    const getLinkClass = (path) => {
        return location.pathname === path ? 'current' : '';
    };
    const getDropdownClass = (pathPrefix) => {
        return location.pathname.startsWith(pathPrefix) ? 'current' : '';
    };

    return (<>
        <header className="main-header header-style-three">
            <div className="header-top">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="top-left">
                            <ul className="social-icon-four">
                                <li><a href="https://www.facebook.com/Davtransportremovals/"><i className="icon fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/davtransportnz/?igsh=MWg0dzJ1ZDBqcjZ3Nw%3D%3D"><i className="icon fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div className="top-right">
                            <ul className="list-style-one light">
                                <li><i className="fa fa-phone"></i> <a href="tel:0800366683">0800 366 683</a></li>
                                <li><i className="fa fa-envelope"></i> <a href="mailto: davtransport.ltd@gmail.com"><span className="__cf_email__"
                                    data-cfemail="e69395839488878b83a682898b878f88c885898b">davtransport.ltd@gmail.com</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-lower">
                <div className="auto-container">
                    <div className="main-box">
                        <div className="logo-box">
                            <div className="logo"><Link to="/"><img className="logoImg" src={logo} alt="Logo" /></Link></div>
                        </div>
                        <div className="nav-outer">
                            <nav className="nav main-menu">
                                <ul className="navigation">
                                    <li><Link to="/">Home</Link></li>
                                    <li className={`dropdown mr_2 ${getDropdownClass("/services")}`}><Link to="/services">Services</Link>
                                        <ul>
                                            <li className="dropdown"><Link to="/services/house">House & Office Moving</Link>
                                                <ul>
                                                    <li><a href="/services/house">Retirement Home</a></li>
                                                    <li><a href="/services/house">Apartment</a></li>
                                                    <li><a href="/services/house">Flat</a></li>
                                                    <li><a href="/services/house">Storage</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><Link to="/services/commercialdelivery">Commercial Deliveries</Link>
                                                <ul>
                                                    <li><Link to="/services/commercialdelivery">Bulk Run Deliveries</Link></li>
                                                    <li><Link to="/services/commercialdelivery">Office Movers</Link></li>
                                                    <li><Link to="/services/commercialdelivery">Business Relocation</Link></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><Link to="/services/packing">Packing Supplies</Link>
                                                <ul>
                                                    <li><Link to="/services/packing">Boxes (different Sizes)</Link></li>
                                                    <li><Link to="/services/packing">Moving Blanket</Link></li>
                                                    <li><Link to="/services/packing">Bubblewrap</Link></li>
                                                    <li><Link to="/services/packing">Shrinkwrap</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/services/furniture">Furniture & Whiteware Deliveries</Link></li>
                                            <li><Link to="/services/b2b">B2B (Business To Business freight)</Link></li>
                                            <li><Link to="/services/fragilefreight">Fragile Freight</Link></li>
                                            <li><Link to="/services/samedaydelivery">Same Day Deliveries</Link></li>
                                            <li><Link to="/services/storage">Storage</Link></li>
                                            <li><Link to="/services/linehaultruck">Line Haul Truck (Cover whole North
                                                Island)</Link></li>
                                        </ul>
                                    </li>
                                    <li className={`${getLinkClass("/mymove")}`}><Link to="/mymove">My Move</Link></li>
                                    <li className={`${getLinkClass("https://www.davtransport.co.nz/freight/")}`}><a href="https://www.davtransport.co.nz/freight/" target="_blank" rel="noopener noreferrer"> General Freight </a></li>
                                    <li className={`${getLinkClass("/about")}`}><Link to="/about">About Us</Link></li>
                                    <li className={`${getLinkClass("/contact")}`}><Link to="/contact">Contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="outer-box">

                            <ButtonReusable InnerText="Booking" PathLink="/booking" ClassName="theme-btn btn-style-one" />

                            <div className="mobile-nav-toggler"><span onClick={openMobileNav} className="icon color000"><RxHamburgerMenu /></span></div>
                        </div>
                    </div>
                </div>
            </div>
            <MobileNav isOpen={isMobileNavOpen} onClose={closeMobileNav} />
            <div className="sticky-header">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="logo">
                            <Link to="/"><img src={logo} alt="" /></Link>
                        </div>
                        <div className="nav-outer">
                            <nav className="main-menu">
                                <div className="navbar-collapse show collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li><Link to="/">Home</Link></li>
                                        <li className={`dropdown ${getDropdownClass("/services")}`}><Link to="/services">Services</Link>
                                            <ul>
                                                <li className="dropdown"><Link to="/services/house">House & Office Moving</Link>
                                                    <ul>
                                                        <li><a href="/services/house">Retirement Home</a></li>
                                                        <li><a href="/services/house">Apartment</a></li>
                                                        <li><a href="/services/house">Flat</a></li>
                                                        <li><a href="/services/house">Storage</a></li>
                                                    </ul>
                                                </li>
                                                <li className={`dropdown`}><Link to="/services/commercialdelivery">Commercial Deliveries</Link>
                                                    <ul>
                                                        <li><Link to="/services/commercialdelivery">Bulk Run Deliveries</Link></li>
                                                        <li><Link to="/services/commercialdelivery">Office Movers</Link></li>
                                                        <li><Link to="/services/commercialdelivery">Business Relocation</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown"><Link to="/services/packing">Packing Supplies</Link>
                                                    <ul>
                                                        <li><Link to="/services/packing">Boxes (different Sizes)</Link></li>
                                                        <li><Link to="/services/packing">Moving Blanket</Link></li>
                                                        <li><Link to="/services/packing">Bubblewrap</Link></li>
                                                        <li><Link to="/services/packing">Shrinkwrap</Link></li>
                                                    </ul>
                                                </li>                                                
                                                <li><Link to="/services/furniture">Furniture & Whiteware Deliveries</Link></li>
                                                <li><Link to="/services/b2b">B2B (Business To Business freight)</Link></li>
                                                <li><Link to="/services/fragilefreight">Fragile Freight</Link></li>
                                                <li><Link to="/services/samedaydelivery">Same Day Deliveries</Link></li>
                                                <li><Link to="/services/storage">Storage</Link></li>
                                                <li><Link to="/services/linehaultruck">Line Haul Truck (Cover whole North
                                                    Island)</Link></li>
                                            </ul>
                                        </li>
                                        <li className={`${getLinkClass("/mymove")}`}><Link to="/mymove">My Move</Link></li>
                                        <li className={`${getLinkClass("https://www.davtransport.co.nz/freight/")}`}><a href="https://www.davtransport.co.nz/freight/" target="_blank" rel="noopener noreferrer"> General Freight </a></li>
                                        <li className={`${getLinkClass("/about")}`}><Link to="/about">About Us</Link></li>
                                        <li className={`${getLinkClass("/contact")}`}><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </nav>
                            <div className="mobile-nav-toggler"><span className="icon" onClick={openMobileNav}><RxHamburgerMenu /></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <Outlet />
    </>)

};

export default Header1;