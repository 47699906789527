import React from "react";
import ServiceDetail from "./ServiceDetail";

const SameDayDelivery = () => {

    return (<>
        <ServiceDetail
            OverviewP1="Too Big for Your Car? Did you know Dav Transport Limited can handle the pick-up and delivery of your large item purchases for both home and business with our Same Day Delivery service."

            Heading="Same Day Delivery"

            OverviewP2="Even it’s a same day house move call we can make your house move happen same day. Serving Whangarei, Auckland, Hamilton, New Plymouth, Palmerston North and Hastings/Napier, our professional team operates seven days a week. Trust us for efficient and reliable moving and delivery solution across North Island."

            Para1="DAV Transport believe all our customer requirements are different, that’s why we cater to our customers according to their requirement. DAV transport provides a range of extra services of packing, unpacking, assembly and disassembly. We have a robust process. Once you give us a call, we will start discussing your moving requirements, and we’ll design a personalized moving strategy"

            Para2="After that our skilled team arrives prepared, ensuring your belongings are packed and secured. on Your moving day we carried out your move with precision and keeping you informed throughout the process. At the end of your move, we cautiously place your items in your new house and offer unpacking assistance for seamless transition. "
        />
    </>)

};

export default SameDayDelivery;