import React from "react";
import ServiceDetail from "./ServiceDetail";

const Furniture = () => {

    return (<>

        <ServiceDetail
            OverviewP1="You can count on DAV transport for an efficient and affordable pick-up and delivery service for furniture, whiteware and other large items. We will do the heavy lifting for you! We have a great relationship with local retailers and take care of your delivering purchases from major outlets. We provide assembly of every type of furniture & whiteware including couches to big French door fridges. "

            OverviewP2="We tailored our service according to your requirements and we provide a range of additional services of upstairs delivery, rubbish removal, rubbish disposal and balcony lift. So, call us now for hassle-free delivery service."

            Heading="Furniture and WhiteWare Deliveries"

            Para1="DAV Transport believe all our customer requirements are different, that’s why we cater to our customers according to their requirement. DAV transport provides a range of extra services of packing, unpacking, assembly and disassembly. We have a robust process. Once you give us a call, we will start discussing your moving requirements, and we’ll design a personalized moving strategy"

            Para2="After that our skilled team arrives prepared, ensuring your belongings are packed and secured. on Your moving day we carried out your move with precision and keeping you informed throughout the process. At the end of your move, we cautiously place your items in your new house and offer unpacking assistance for seamless transition. "
        />

    </>)

};

export default Furniture;