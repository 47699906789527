import React from "react";
import ServiceDetail from "./ServiceDetail";

const CommercialDelivery = () => {

    return (<>

        <ServiceDetail
            OverviewP1="DAV Transport delivers to major retailers in all regions of North Island. Does not matter how small and how big the job is we delivery with precise care and timely manner. We have the flexibility to provide the right solution for your commercial order’s needs. The operation involves picking up your consignment to be delivered to the customer. We provide seamless service with real time management where you can check how far is your consignment from reciever. We are adapting new techniques according to technology and changing market while putting our customers needs first and staying ahead of the competition."

            Heading="Commercial Delivery"

            Para1="There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable.There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable."

            Para2="After that our skilled team arrives prepared, ensuring your belongings are packed and secured. on Your moving day we carried out your move with precision and keeping you informed throughout the process. At the end of your move, we cautiously place your items in your new house and offer unpacking assistance for seamless transition."
        />

    </>);

};

export default CommercialDelivery;