import React, { useEffect, useState } from "react";
import "../UI-style/Contact.css";
import MainBanner from "../../src/DAVtranportImage/contact.webp";
import { FaPhoneAlt } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import Footer from "./Common/Footer";
import JsButton from "./Common/JsButton";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import swal from 'sweetalert';
import { ToTop } from "./Common/ToTop";
import { Cities } from "../utils/constants";
// import { TfiHeadphoneAlt } from "react-icons/tfi";
// import { TbTruckDelivery } from "react-icons/tb";
// import { SlCalender } from "react-icons/sl";
// import { RiSecurePaymentLine } from "react-icons/ri";

const Contact = (props) => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	const [name, setName] = useState("");
	const [subject, setSubject] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [city, setCity] = useState("");
	const [loading, setLoading] = useState(false);

	const phoneRegex = /^\d{10}$/;
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	const {
		REACT_APP_CONTACT_TEMPLATE_ID,
		REACT_APP_EMAIL_PUBLIC_KEY,
		REACT_APP_EMAIL_SERVICE_ID,
	} = process.env;

	function toCapital(str) {
		return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
	}

	const handleSubmitContact = (e) => {
		e.preventDefault();
		if (
			name &&
			subject &&
			message &&
			phoneRegex.test(phone) &&
			emailRegex.test(email) &&
			city
		) {
			setLoading(true);
			emailjs
				.send(
					REACT_APP_EMAIL_SERVICE_ID,
					REACT_APP_CONTACT_TEMPLATE_ID,
					{
						user: toCapital(name),
						useremail: email,
						message,
						phone,
						subject,
						city
					},
					{
						publicKey: REACT_APP_EMAIL_PUBLIC_KEY,
					}
				)
				.then(
					() => {
						swal("Done", "Thank You for contacting us!", "success");
						setLoading(false);
						setName("");
						setSubject("");
						setPhone("");
						setEmail("");
						setMessage("");
						setCity("");
					},
					(error) => {
						swal("Error", "Unexpected error is there. Please try again", "error");
						setLoading(false);
					}
				);
		} else {
			swal("Error", "Please Fill All Details in Above Fields", "error");
		}
	};

	return (
		<>
			<section
				className="page-title"
				style={{ backgroundImage: `url(${MainBanner})` }}
			>
				<div className="auto-container">
					<div className="title-outer text-center">
						<h1 className="title">Contact Us</h1>
						<ul className="page-breadcrumb">
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>Contact Us</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="contact-details">
				<div className="container pb-70">
					<div className="row">
						<div className="col-xl-7 col-lg-6">
							<div className="sec-title">
								<span className="sub-title">Send us email</span>
								<h2>Feel free to write</h2>
							</div>
							<form
								id="contact_form"
								name="contact_form"
								action="https://html.kodesolution.com/2024/#-html/includes/sendmail.php"
								method="post"
							>
								<div className="row">
									<div className="col-sm-6">
										<div className="mb-3">
											<input
												name="form_name"
												className="form-control text_000"
												type="text"
												placeholder="Enter Name"
												onChange={(e) => {
													setName(e.target.value);
												}}
												value={name}
											/>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="mb-3">
											<input
												name="form_email"
												className="form-control required email text_000"
												type="email"
												placeholder="Enter Email"
												onChange={(e) => {
													setEmail(e.target.value);
												}}
												value={email}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<div className="mb-3">
											<input
												name="form_subject"
												className="form-control required text_000"
												type="text"
												placeholder="Enter Subject"
												onChange={(e) => {
													setSubject(e.target.value);
												}}
												value={subject}
											/>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="mb-3">
											<input
												name="form_phone"
												className="form-control text_000"
												type="text"
												placeholder="Enter Phone"
												onChange={(e) => {
													setPhone(e.target.value);
												}}
												value={phone}
											/>
										</div>
									</div>
									<div className="col-sm-12">
										<div className="mb-3">
											<select
												onChange={(e) => setCity(e.target.value)}
												className="w-100"
												value={city}
											>
												<option disabled className="text_000" value="">
													Select City
												</option>
												{Cities.map((cityName, i) => {
													return (
														<option key={i} className="text_000" value={cityName.value}>
															{cityName?.title}
														</option>
													);
												})}
											</select>
										</div>
									</div>
								</div>
								<div className="mb-3">
									<textarea
										name="form_message"
										className="form-control required text_000"
										rows="7"
										placeholder="Enter Message"
										onChange={(e) => {
											setMessage(e.target.value);
										}}
										value={message}
									></textarea>
								</div>
								<div className="mb-5">
									<input
										name="form_botcheck"
										className="form-control"
										type="hidden"
										value
									/>
									<JsButton Type="submit" onClick={handleSubmitContact}>
										{loading ? "Loading..." : "Send Message"}
									</JsButton>

									<JsButton Type="reset" ClassName="ml-25">
										Reset
									</JsButton>
								</div>
							</form>
						</div>
						<div className="col-xl-5 col-lg-6">
							<div className="contact-details__right">
								<div className="sec-title">
									<span className="sub-title">Need any help?</span>
									<h2>Get in touch with us</h2>
									<div className="text">
										For any questions, concerns, or inquiries, our support team
										is available. Contact us via phone, email or on our website
										with your service needs.
									</div>
								</div>
								<ul className="list-unstyled contact-details__info">
									<li>
										<div className="icon">
											<span>
												<FaPhoneAlt />
											</span>
										</div>
										<div className="text">
											<a href="tel:0800366683"> 0800 366 683 </a>
											<br />
											<a href="tel:0211381008"> 021 138 1008</a>
										</div>
									</li>
									<li>
										<div className="icon">
											<span>
												<FaPhoneAlt />
											</span>
										</div>
										<div className="text">
											<a href="tel:0224932750"> 022 493 2750</a>
											<br />
											<a href="tel:0221729568"> 022 172 9568</a>
										</div>
									</li>
									<li>
										<div className="icon">
											<span>
												<MdMailOutline />
											</span>
										</div>
										<div className="text">
											<a href="mailto: davtransport.ltd@gmail.com">
												<span
													className="__cf_email__"
													data-cfemail="a9c7cccccdc1ccc5d9e9cac6c4d9c8c7d087cac6c4"
												>
													davtransport.ltd@gmail.com
												</span>
											</a>
											<br />
											<a href="mailto: aucklanddavtransport@gmail.com">
												<span
													className="__cf_email__"
													data-cfemail="a9c7cccccdc1ccc5d9e9cac6c4d9c8c7d087cac6c4"
												>
													aucklanddavtransport@gmail.com
												</span>
											</a>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="services-details">
				<div className="container p-0 pb-5">
					<div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="services-details__content">
								<h3 className="mt-4">Our Services:</h3>
								<div className="content mt-40">
									<div className="feature-list mt-4">
										<div className="row clearfix">
											<div className="col-lg-6 col-md-6 col-sm-12 column">
												<p><b>SAME DAY FREIGHT DELIVERY:</b> Our passionate team working 7 days a week to provide a prompt service with same day Pickup and delivery service. Even it’s a same day house move call or same day general freight delivery service we going to make it happen for you.</p>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12 column">
												<p><b>URGENT INTER CITY DELIVERY:</b> DAV Transport offer a weekly run to all regions in North Island. even if you have one-off item or bulk items going to one region to another region or multiple regions, we can keep things moving and finish the job on urgent basis. At the end we only want satisfied and happy customers. As our mission “your business is our business”.</p>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12 column">
												<p><b>AFTER HOURS DELIVERY:</b> We can cater our service according to your need. If you can’t accept the delivery during the business hours for any reason, don’t worry we can deliver your goods after hours for you. As we understand sometime its hard and frustrating when something come up and your day schedule twisted. So, feel free to call us to get that service.</p>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12 column">
												<p><b>SUPPLY CHAIN SOLUTIONS:</b> DAV Transport have a range of trucks to suit all supply chain requirements for the manufacturer in north island. From Van and 4 wheel to 6 wheel trucks and majority of our trucks equipped with tail lift. We cover all North Island areas weekly even if the delivery going to rural areas or city you can count on us to complete your shipment.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="map-section py-0">
				<iframe
					title="NZ Map"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51833.188168817804!2d174.24485918747416!3d-35.712092987300636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0b829c4e6b10c7%3A0x500ef6143a39927!2sWhang%C4%81rei%2C%20New%20Zealand!5e0!3m2!1sen!2sin!4v1718263561267!5m2!1sen!2sin"
					width="100%"
					height="550"
					style={{ border: 0 }}
					allowFullScreen=""
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
				></iframe>
			</section>

			<Footer />

			<ToTop />
		</>
	);
};

export default Contact;
