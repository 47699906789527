import React from 'react';
import Form from 'react-bootstrap/Form';

function SelectTag(props) {
  const { Options, Label, Info, onChange, selectClass, labelClass, value } = props;
  return (
    <Form.Group className={`mt-4 ${labelClass}`}>
      {Label}
      <Form.Select className={`focus-ring focus-ring-light select-item ${selectClass}`} placeholder="Select" value={value} onChange={(e) => onChange(e.target.value)}>
        {Options.map((option, index) => {
          return (
            <option className='text_000' key={index} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </Form.Select>
      <span className='info-text'>{Info}</span>
    </Form.Group>
  )
}

export default SelectTag;