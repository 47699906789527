import React, { useEffect } from "react";
import "../UI-style/Index.css";
import Man from "../../src/DAVtranportImage/images/final/19.webp";
import Man1 from "../DAVtranportImage/images/Man3.webp";
import Empty from "../DAVtranportImage/images/Empty.webp";
import Map from "../../src/DAVtranportImage/images/final/4.webp";
import truck from "../../src/DAVtranportImage/IMG_5838.webp";
import truck1 from "../../src/DAVtranportImage/images/final/13.webp";
import topImage from "../../src/DAVtranportImage/images/final/homeChild.webp";
import Image2 from "../../src/DAVtranportImage/images/final/12.webp";
import shape from "../../src/DAVtranportImage/shape_1.webp";
import shape2 from "../../src/Images/banner/shape2.webp";
import Contact from "../../src/DAVtranportImage/youtube1.webp";
import secServ1 from "../../src/DAVtranportImage/images/final/8.webp";
import secServ5 from "../../src/DAVtranportImage/images/final/1.webp";
import secServ3 from "../../src/DAVtranportImage/images/final/10.webp";
import secServ2 from "../../src/DAVtranportImage/davTrans_1.webp";
import secServ4 from "../../src/DAVtranportImage/additional-services-1.webp";
import serv1 from "../../src/DAVtranportImage/download1.webp";
import serv2 from "../../src/DAVtranportImage/Untitled design (2).webp";
import serv3 from "../../src/DAVtranportImage/furniture.webp";
import serv4 from "../../src/DAVtranportImage/b2b.webp";
import serv5 from "../../src/DAVtranportImage/Freight-For-Fragile-Goods7-1.webp";
import serv6 from "../../src/DAVtranportImage/same-day-delivery.webp";
import serv7 from "../../src/DAVtranportImage/Storage.webp";
import serv8 from "../../src/DAVtranportImage/additional-services-1.webp";
import process from "../../src/Images/background/9.webp";
import phone from "../../src/Images/resource/process1-1.webp";
import typeserv from "../../src/Images/resource/process1-2.webp";
import book from "../../src/Images/resource/process1-3.webp";
import order from "../../src/Images/resource/process1-4.webp";
import client from "../DAVtranportImage/images/user-avatar.webp";
import clientLogo1 from "../../src/DAVtranportImage/beds-r-us.webp";
import clientLogo2 from "../../src/DAVtranportImage/beds-and-more.webp";
import clientLogo3 from "../../src/DAVtranportImage/bed-stop-1024x536.webp";
import clientLogo4 from "../../src/DAVtranportImage/bigsave-1024x1024.webp";
import clientLogo5 from "../../src/DAVtranportImage/dunamis-haulage.webp";
import clientLogo6 from "../../src/DAVtranportImage/furniture-now.webp";
import clientLogo7 from "../../src/DAVtranportImage/furniture-zone-use.webp";
import clientLogo8 from "../../src/DAVtranportImage/harvey-norman.webp";
import clientLogo9 from "../../src/DAVtranportImage/kitchen-things.webp";
import clientLogo10 from "../../src/DAVtranportImage/PBT.webp";
import clientLogo11 from "../../src/DAVtranportImage/trade-me.webp";
import clientLogo12 from "../../src/DAVtranportImage/furnitureHaven.webp";
import clientLogo13 from "../../src/DAVtranportImage/paramountFurniture.webp";
import clientLogo14 from "../../src/DAVtranportImage/sofaFactory.webp";
import clientLogo15 from "../../src/DAVtranportImage/educatedLogo.webp";
import clientLogo16 from "../../src/DAVtranportImage/jory-henley.webp";
import clientLogo17 from "../DAVtranportImage/fabers.webp";
import clientLogo18 from "../DAVtranportImage/RL-logo.webp";
import { TbTruckDelivery, TbTrolley } from "react-icons/tb";
import { FaBoxOpen, FaGlassMartini } from "react-icons/fa";
import { IoIosCheckboxOutline } from "react-icons/io";
import { BsBoxes } from "react-icons/bs";
import { RiSofaLine } from "react-icons/ri";
import AboutSec from "./Common/AboutSec";
import ButtonReusable from "../../src/UI-Components/Common/ButtonReusable";
import Clients from "./Common/Clients";
import Footer from "./Common/Footer";
import ServicesCom from "./Common/ServicesCom";
import Process from "./Common/Process";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "jquery-ui";
import { MdOutlineWarehouse } from "react-icons/md";
import { IoBusinessOutline } from "react-icons/io5";
import { GiBoxUnpacking } from "react-icons/gi";
import { Link } from "react-router-dom";
import { ToTop } from "./Common/ToTop";
import FormComponent from "./Common/FormComponent";
import MainCarousel from "./Common/MainCarousel";
import SwiperContent from "./Common/SwiperContent";

// ANIMATE lib
import "animate.css/animate.min.css";

// wow lib
import WOW from "wowjs";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const Index = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const optionBanner = {
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 9000,
		nav: true,
		smartSpeed: 1000,
		dots: true,
		items: 1,
		responsive: {
			1440: 1,
			767: 1,
			576: 1,
		},
	};

	const optionService = {
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		nav: false,
		dots: true,
		smartSpeed: 600,
		items: 3,
		responsive: {
			1180: {
				items: 3,
			},
			768: {
				items: 2,
			},
			0: {
				items: 1,
			},
		},
	};

	const optionLogos = {
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 300,
		nav: false,
		dots: true,
		responsive: {
			1200: {
				slideBy: 4,
				items: 4,
			},
			1000: {
				slideBy: 3,
				items: 3,
			},
			768: {
				slideBy: 2,
				items: 2,
			},
			0: {
				slideBy: 1,
				items: 1,
			},
		},
	};

	useEffect(() => {
		new WOW.WOW().init();
	}, []);

	useEffect(() => {
		const prefetchImages = [truck1, truck, Map, Man, Man1, Empty, topImage, Image2, shape, shape2, Contact, secServ1, secServ2, secServ3, secServ4, secServ5, serv1, serv2, serv3, serv4, serv5, serv6, serv7, serv8, process, phone, typeserv, book, order, client, clientLogo1, clientLogo10, clientLogo11, clientLogo12, clientLogo13, clientLogo14, clientLogo15, clientLogo16, clientLogo17, clientLogo18, clientLogo2, clientLogo3, clientLogo4, clientLogo5, clientLogo6, clientLogo7, clientLogo8, clientLogo9 ];

		prefetchImages.forEach((image) => {
			const link = document.createElement("link");
			link.rel = "prefetch";
			link.href = image;
			link.as = "image";
			document.head.appendChild(link);
		});
	}, []);

	return (
		<>
			<section className="banner-section">
				<OwlCarousel
					className="banner-carousel owl-carousel owl-theme default-dots-two disable-navs"
					{...optionBanner}
				>
					<MainCarousel
						Bg={truck1}
						shape1={shape}
						shape2={shape2}
						FrontImage={Man}
						Heading="Professional Home Furniture and Office Moving Services for a Seamless Stress-Free Move"
						Para="We understand the challenges that relocation brings and thus offer a wide range of moving services to make your move successful. We move your belongings with the utmost care as if they were our own. We offer fully customized moving services to ensure a seamless experience, no matter where life takes you next."
					/>

					<MainCarousel
						Bg={truck}
						shape1={shape}
						shape2={shape2}
						FrontImage={Man1}
						Heading="Specializing in Supply Chain Solutions and Freight Delivery Across the North Island"
						Para="We pride ourselves on meeting customer expectations and keeping customer service first. No matter if it’s a same day delivery and how big or how small the consignment is we can make it happen in a timely manner. We drive to the north island weekly to provide a line haul delivery service to our customers on an urgent basis."
					/>

					<MainCarousel
						Bg={Map}
						shape1={shape}
						shape2={shape2}
						FrontImage={Empty}
						Heading="Looking for a Packing and Storage Service Solution for your House or Commercial Move"
						Para="Don't feel like packing anything? Not an issue! We offer customizable packing services to suit the needs of any move. Do not worry if you are relocating to new premises that cannot accommodate all the items; we are here to help! Our moving company is fully equipped to handle all your storage needs for any distance or size."
					/>
				</OwlCarousel>
			</section>

			<section className="about-section">
				<div className="auto-container">
					<div className="row">
						<div className="content-column col-lg-7 col-md-12 col-sm-12 order-lg-2">
							<div className="inner-column ">
								<div className="sec-title">
									<span className="sub-title wow fadeInRight">
										Customer Teams & An Agile Services
									</span>
									<h2 className="words-slide-up text-split wow fadeInRight">
										Logistics Partner To <br className="hde" /> Famous Companies
										<br className="hde" />
										<span className="color">Since 2015.</span>
									</h2>
									<div className="text text_16">
										We have proudly served as the trusted logistics partner for
										some of the New Zealand's renowned companies. Our expertise
										in supply chain management and commitment have enabled us to
										support these industry leaders with efficient, reliable, and
										innovative logistics solutions.
									</div>
								</div>
							</div>
						</div>

						<div className="image-column col-lg-5 col-md-12 col-sm-12 ">
							<div className="inner-column">
								<div className="image-box">
									<figure className="image overlay-anim reveal">
										<img className="wow fadeInLeft" src={topImage} alt="" />
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="auto-container">
					<div className="row">
						<div
							className="content-column style-two col-lg-7 col-md-12 col-sm-12 order-lg-2"
							data-wow-delay="300ms"
						>
							<div className="inner-column">
								<div className="row">
									<AboutSec
										Icon={<FaBoxOpen />}
										title="Transparent Pricing"
										description="We provide an estimated price by calculating the time required for your job. Following the quality of our service thus having gained trust of our many clients."
									/>

									<AboutSec
										Icon={<IoIosCheckboxOutline />}
										title="Fast, Efficient Delivery"
										description="To do this, we factor in the items you list, access at each address, and the suburbs involved in the move."
									/>
								</div>
								{/* <div className="auther-box">
								<div className="inner-box">
									<div className="author-image">
										<img src={CEO} alt="Image" />
									</div>
									<div className="author-info">
										<h6 className="name">Abhinav</h6>
										<span className="designation">CEO, DAV Transport</span>
									</div>
								</div>
								<div className="sign"><img src={Sign} alt="Image" /></div>
							</div> */}
							</div>
						</div>

						<div className="image-column style-two col-lg-5 col-md-12 col-sm-12 align_center_992">
							<div className="inner-column">
								<div className="image-box wow fadeInLeft">
									<figure className="image overlay-anim reveal">
										<img src={Image2} alt="" />
									</figure>
									<div className="exp-box bounce-y">
										<i>
											<TbTruckDelivery />
										</i>
										<h3 className="title">612 k</h3>
										<span>Delivered Goods</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="contact-section pt-0">
				<div className="icon-city"></div>
				<div className="auto-container">
					<div className="sec-title-outer">
						<div className="sec-title">
							<span className="sub-title">Request a Quote</span>
							<h2 className="words-slide-up text-split">
								We Create Opportunity to <br />
								Reach Potential
							</h2>
						</div>
						<div className="contact-box">
							<i className="icon fal fa-mobile"></i>
							<div className="content">
								<p>Emergency Call:</p>
								<a href="tel:0800366683" className="contact-no">
									0800 366 683
								</a>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<FormComponent custom_class="w_67" />
						<div className="video-column col-lg-4 col-md-12 col-sm-12 wow fadeInRight d-lg-block">
							<div className="inner-column">
								<figure className="image">
									<img src={Contact} alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="services-section pt-0 ">
				<div className="auto-container">
					<div className="outer-box">
						<OwlCarousel className="outer-box" {...optionService}>
							<ServicesCom
								Title="House & Office Moving Services"
								PathLink="/services/house"
								Icon={<BsBoxes />}
								Image={serv1}
								Description="DAV Transport, the house and office moving specialist, that locals trust. Offering a stress-free service at affordable price, providing a smooth move."
							/>

							<ServicesCom
								Title="Commercial Deliveries"
								PathLink="/services/commercialdelivery"
								Icon={<TbTrolley />}
								Image={serv2}
								Description="Our Dav Transport does commercial orders for few retailer stores and manufacturer in North Island. Flexible to provide right solution for your orders."
							/>

							<ServicesCom
								ClassName="cus-width"
								PathLink="/services/furniture"
								Image={serv3}
								Icon={<RiSofaLine />}
								Description="Our furniture moving service offers a stress-free experience with professional handling, secure transportation, and timely delivery to your new location."
								Title="Furniture Deliveries"
							/>

							<ServicesCom
								Title="Business-to-Business Service"
								PathLink="/services/b2b"
								Icon={<IoBusinessOutline />}
								Image={serv4}
								Description="Business to Business transfer service for many retailer store in North Island. DAV transport cover every corner in the North Island at least once in a week."
							/>

							<ServicesCom
								Title="Fragile Freight Services"
								PathLink="/services/fragilefreight"
								Icon={<FaGlassMartini />}
								Image={serv5}
								Description="Our fragile freight service ensures the safe and secure transport of your delicate items, with specialized packaging and handling for peace of mind."
							/>

							<ServicesCom
								Title="Same Day Delivery Services"
								PathLink="/services/samedaydelivery"
								Icon={<BsBoxes />}
								Image={serv6}
								Description="DAV transport same-day delivery service guarantees swift and reliable delivery of your items and goods, ensuring they reach at your doorsteps within few hours."
							/>

							<ServicesCom
								Title="Industrial Storage Services"
								PathLink="/services/storage"
								Icon={<MdOutlineWarehouse />}
								Image={serv7}
								Description="DAV's storage service offers secure, climate-controlled units with flexible rental, ensuring your belongings are safe and accessible when you need them."
							/>

							<ServicesCom
								Title="Packing Services (Boxes, etc.)"
								PathLink="/services/packing"
								Icon={<GiBoxUnpacking />}
								Image={serv8}
								Description="Our packing service ensures your items are securely, efficiently packed using high-quality materials, providing peace of mind during move or shipment."
							/>
						</OwlCarousel>
					</div>
				</div>
			</section>

			<section className="process-section pt-0">
				<div
					className="bg bg-image"
					style={{ backgroundImage: `url(${process})` }}
				></div>
				<div className="icon-bus"></div>
				<div className="auto-container">
					<div className="sec-title text-center">
						<span className="sub-icon"></span>
						<span className="sub-title">
							Customer Teams & An Agile Services
						</span>
						<h2 className="words-slide-up text-split">
							Working Process For Services
						</h2>
					</div>
					<div className="outer-box">
						<div className="icon-line"></div>
						<div className="row">
							<Process
								Title="Online Booking"
								Count="1"
								Image={phone}
								Description="Easily book our logistics services online for fast and efficient delivery."
							/>

							<Process
								Delay="300ms"
								Title="Select Service Type"
								Count="2"
								Image={typeserv}
								Description="Choose your preferred service type for our logistics solutions."
							/>

							<Process
								Delay="600ms"
								Title="Book"
								Count="3"
								Image={book}
								Description="You easily select, confirm your service, ensuring hassle-free secure transaction"
							/>

							<Process
								Delay="900ms"
								Title="Track Order"
								Image={order}
								Count="4"
								Description="Track your order with timely updates, providing you with status and location of your shipment."
							/>
						</div>
						<div className="btn-box">
							<ButtonReusable
								InnerText="Get a Quote"
								PathLink="/booking"
								ClassName="theme-btn btn-style-one"
							/>
							<a href="tel:0800366683" className="contact-btn">
								<i className="fa-solid fa-phone-volume"></i>0800 366 683
							</a>
						</div>
					</div>
				</div>
			</section>

			<section className="work-section">
				<div className="auto-container">
					<div className="sec-title">
						<span className="sub-title">Our Portfolio</span>
						<h2 className="words-slide-up text-split">
							Explore Our Recent Work
						</h2>
					</div>
					<div className="outer-box">
						<div className="work-block active wow fadeInUp">
							<div className="inner-box">
								<div className="image-box">
									<figure className="image">
										<Link to="/services/house">
											<img src={secServ1} alt="" />
										</Link>
									</figure>
								</div>
								<div className="content-box">
									<span className="cat">Transportation</span>
									<h4 className="title">
										<Link to="/services/house">House & Office Moving</Link>
									</h4>
									<Link to="/services/house" className="read-more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
								<div className="content-box-hover">
									<h4 className="title">
										<Link to="/services/house">House & Office Moving</Link>
									</h4>
								</div>
								<div className="overlay-1"></div>
							</div>
						</div>

						<div className="work-block wow fadeInUp" data-wow-delay="300ms">
							<div className="inner-box">
								<div className="image-box">
									<figure className="image">
										<Link to="/services/commercialdelivery">
											<img src={secServ2} alt="" />
										</Link>
									</figure>
								</div>
								<div className="content-box">
									<span className="cat">Transportation</span>
									<h4 className="title">
										<Link to="/services/commercialdelivery">
											Commercial delivery
										</Link>
									</h4>
									<Link to="/services/commercialdelivery" className="read-more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
								<div className="content-box-hover">
									<h4 className="title">
										<Link to="/services/commercialdelivery">
											Commercial delivery
										</Link>
									</h4>
								</div>
								<div className="overlay-1"></div>
							</div>
						</div>

						<div className="work-block wow fadeInUp" data-wow-delay="600ms">
							<div className="inner-box">
								<div className="image-box">
									<figure className="image">
										<Link to="/services/furniture">
											<img src={secServ3} alt="" />
										</Link>
									</figure>
								</div>
								<div className="content-box">
									<span className="cat">Transportation</span>
									<h4 className="title">
										<Link to="/services/furniture">Furniture Deliveries</Link>
									</h4>
									<Link to="/services/furniture" className="read-more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
								<div className="content-box-hover">
									<h4 className="title">
										<Link to="/services/furniture">Furniture Deliveries</Link>
									</h4>
								</div>
								<div className="overlay-1"></div>
							</div>
						</div>

						<div className="work-block wow fadeInUp" data-wow-delay="900ms">
							<div className="inner-box">
								<div className="image-box">
									<figure className="image">
										<Link to="/services/packing">
											<img src={secServ4} alt="" />
										</Link>
									</figure>
								</div>
								<div className="content-box">
									<span className="cat">Transportation</span>
									<h4 className="title">
										<Link to="/services/packing">Packaging Services</Link>
									</h4>
									<Link to="/services/packing" className="read-more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
								<div className="content-box-hover">
									<h4 className="title">
										<Link to="/services/packing">Packaging Services</Link>
									</h4>
								</div>
								<div className="overlay-1"></div>
							</div>
						</div>
						<div className="work-block wow fadeInUp" data-wow-delay="1200ms">
							<div className="inner-box">
								<div className="image-box">
									<figure className="image">
										<Link to="/services/samedaydelivery">
											<img src={secServ5} alt="" />
										</Link>
									</figure>
								</div>
								<div className="content-box">
									<span className="cat">Transportation</span>
									<h4 className="title">
										<Link to="/services/samedaydelivery">
											Same Day Deliveries
										</Link>
									</h4>
									<Link to="/services/samedaydelivery" className="read-more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
								<div className="content-box-hover">
									<h4 className="title">
										<Link to="/services/samedaydelivery">
											Same Day Deliveries
										</Link>
									</h4>
								</div>
								<div className="overlay-1"></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="testimonial-section">
				<div className="auto-container">
					<div className="sec-title text-center">
						<span className="sub-icon"></span>
						<span className="sub-title">Clients Reviews & Testimonials</span>
						<h2 className="words-slide-up text-split">
							What Client’s say about <br />
							Our Services
						</h2>
					</div>
					<div className="slider-outer">
						<div className="testi-shape1"></div>
						<div className="testi-shape2"></div>
						<Swiper
							navigation={{
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							}}
							spaceBetween={50}
							slidesPerView={1}
							modules={[Navigation, Pagination, Autoplay]}
							loop={true}
							autoplay={{
								delay: 9000,
								disableOnInteraction: false,
							}}
							className="overflow_visible"
						>
							<SwiperSlide>
								<SwiperContent
									Name="Trish Iraia"
									Review="Outstanding Service. Highly Recommended. Our Delivery by trolley, steep driveway. Thanks Noel and Luke. Courteous, Fast, Friendly, Product Knowlegded, Professional. Bless Trish & Kevin, Kamo."
									Image={client}
								/>
							</SwiperSlide>
							<SwiperSlide>
								<SwiperContent
									Name="Tania Watson"
									Review="Had 2 Deliveries from these experienced and talented Guys and both time they have bee organized, quick and polite. They navigated the obstacles with care , patience and attention. ThankYou Guys."
									Image={client}
								/>
							</SwiperSlide>
							<SwiperSlide>
								<SwiperContent
									Name="Till Schlimme"
									Review="On Sunday, Arun and Baldev delivered a SofaBed from Harvey Norman. They run in advance to give me their ETA. Very friendly chaps, brought the piece carefully into my house. They get Five out of Five."
									Image={client}
								/>
							</SwiperSlide>
							<SwiperSlide>
								<SwiperContent
									Name="Rachel Blair"
									Review="Had a delivery from Jot and Noel for my sister they messaged me to say they were 45mins out and were there on time. Very Polite and Friendly service. Great work Guys I'd recommend you. "
									Image={client}
								/>
							</SwiperSlide>

							<SwiperSlide>
								<SwiperContent
									Name="Mike Burr"
									Review="Asdeep and Pradeep, delivered and assembled on site, were thoroughly Professional and very very competent as well as extremely pleasant young men. They upheld the Highest standards of DAV Transport."
									Image={client}
								/>
							</SwiperSlide>
							<SwiperSlide>
								<SwiperContent
									Name="Benjamin P"
									Review="Thanks to Luke and Bodhi For delivering my new fridge from Harvey Norman. They had a very hard job getting up to me. But did it brilliantly and gracefully. ThankYou Guys again for your help."
									Image={client}
								/>
							</SwiperSlide>
							<div className="swiper-button-next">
								<i className="fas fa-arrow-right position-absolute top-0 start-0"></i>
							</div>
							<div className="swiper-button-prev">
								<i className="fas fa-arrow-left position-absolute top-0 start-0"></i>
							</div>
						</Swiper>
					</div>
				</div>
			</section>

			<section className="clients-section pt-0">
				<div className="icon-ship"></div>
				<div className="auto-container">
					<div className="sec-title text-center">
						<span className="sub-title">happy client and sponsor</span>
						<h2 className="words-slide-up text-split">
							Our Trusted Clients And Manufacturers
						</h2>
					</div>
					<div className="sponsors-outer">
						<OwlCarousel
							className="clients-carousel owl-carousel owl-theme default-dots-two disable-navs"
							{...optionLogos}
						>
							<Clients Logo={clientLogo1} />

							<Clients Logo={clientLogo2} />

							<Clients Logo={clientLogo3} />

							<Clients Logo={clientLogo4} />

							<Clients Logo={clientLogo5} />

							<Clients Logo={clientLogo6} />

							<Clients Logo={clientLogo7} />

							<Clients Logo={clientLogo8} />

							<Clients Logo={clientLogo9} />

							<Clients Logo={clientLogo10} />

							<Clients Logo={clientLogo11} />

							<Clients Logo={clientLogo12} />

							<Clients Logo={clientLogo13} />

							<Clients Logo={clientLogo14} />

							<Clients Logo={clientLogo15} />

							<Clients Logo={clientLogo16} />

							<Clients Logo={clientLogo17} />

							<Clients Logo={clientLogo18} />
						</OwlCarousel>
					</div>
				</div>
			</section>

			<Footer />

			<ToTop />
		</>
	);
};

export default Index;
