import React from "react";

// ACCORDION
import "bootstrap/dist/css/bootstrap.min.css"
import { Accordion } from "react-bootstrap";

// CUSTOM CSS
import "../../UI-style/Accord.css";

// ICON
import { GoPlus } from "react-icons/go";



const AccordComp = () => {

    return (<>

        <Accordion className="block" defaultActiveKey={0} >

            <Accordion.Item eventKey={0}>
                <Accordion.Header className="acc-btn">
                    Is there any hidden cost in your service?
                    <div className="icon"><GoPlus /></div>
                </Accordion.Header>
                <Accordion.Body>
                    Thank you for showing your concern. No there is no hidden cost in availing of our house movers service. You have to pay as per the demand of your service package.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3" eventKey={1}>
                <Accordion.Header className="acc-btn">
                    Is my presence necessary for the process?
                    <div className="icon"><GoPlus /></div>
                </Accordion.Header>
                <Accordion.Body>
                    Thank you for putting up this question. Not necessarily but, if you have any specific instructions for us then please let us know prior to the process so that we understand it and work on it accordingly.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3" eventKey={2}>
                <Accordion.Header className="acc-btn">
                    My budget maybe not so much to have your service. What should I do?
                    <div className="icon"><GoPlus /></div>
                </Accordion.Header>
                <Accordion.Body>
                    Thank you for showing your concern. You can customize your package according to your budget right here by fill up your requirements in our Booking form.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3" eventKey={3}>
                <Accordion.Header className="acc-btn">
                    Is there any time zone that your company chooses to offer to deliver your service in?
                    <div className="icon"><GoPlus /></div>
                </Accordion.Header>
                <Accordion.Body>
                    Thank you for showing your concern. Any time of any day our team is ready to provide you with our removalist service.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3" eventKey={4}>
                <Accordion.Header className="acc-btn">
                    Is there any hidden cost in your service?
                    <div className="icon"><GoPlus /></div>
                </Accordion.Header>
                <Accordion.Body>
                    Thank you for showing your interest. No, there is no hidden cost in any of our servicing packages. To get your fridge moving quote contact us today and get a free one.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3" eventKey={5}>
                <Accordion.Header className="acc-btn">
                    Can you move my other furniture items with a wardrobe under the same service package?
                    <div className="icon"><GoPlus /></div>
                </Accordion.Header>
                <Accordion.Body>
                    Thank you for asking this question. Yes, you can easily customize your package by filling out the quotation form available on our website, or you can email us on davtransport.ltd@gmail.com.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3" eventKey={6}>
                <Accordion.Header className="acc-btn">
                    Is there any need for a pre-move survey for wardrobe removal?
                    <div className="icon"><GoPlus /></div>
                </Accordion.Header>
                <Accordion.Body>
                    Thank you for asking this question. No, the pre-move survey is not mandatory but, we suggest you have it because it will clear your all doubts regarding the services you are going to get on the moving day.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3" eventKey={7}>
                <Accordion.Header className="acc-btn">
                    What happens if something bad happens from your side?
                    <div className="icon"><GoPlus /></div>
                </Accordion.Header>
                <Accordion.Body>
                    Thank you for asking this question. We insured your everything if something unfavorable happens from our side. You can get the in-depth detail by simply contact us or write us on mail.
                </Accordion.Body>
            </Accordion.Item>

        </Accordion >

    </>)

};

export default AccordComp;