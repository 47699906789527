import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"

// FILES
import Footer from "./Common/Footer";
import "../../src/UI-style/ServiceDetail.css";
import ListCom from "../../src/UI-Components/Common/ListCom";
import AccordComp from "../../src/UI-Components/Common/Accordion";
import "../../src/UI-style/Accord.css";

// ICON
import { MdOutlineLocalPhone } from "react-icons/md";

// Image
import bg from "../DAVtranportImage/images/final/13.webp";
import MainBanner from "../DAVtranportImage/images/final/4.webp"
import img1 from "../../src/DAVtranportImage/images/final/7.webp";
import img2 from "../../src//DAVtranportImage/images/final/12.webp"
import { Link } from "react-router-dom";
import { ToTop } from "./Common/ToTop";

const ServiceDetail = (props) => {

    useEffect(() => {
		const prefetchImages = [bg, MainBanner, img1, img2 ];

		prefetchImages.forEach((image) => {
			const link = document.createElement("link");
			link.rel = "prefetch";
			link.href = image;
			link.as = "image";
			document.head.appendChild(link);
		});
	}, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (<>

        <section className="page-title" style={{ backgroundImage: `url(${bg})` }}>
            <div className="auto-container">
                <div className="title-outer text-center">
                    <h1 className="title"><b>{props.Heading}</b></h1>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>{props.Heading}</li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="services-details">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4">
                        <div className="service-sidebar">
                            <div className="sidebar-widget service-sidebar-single">
                                <div className="sidebar-service-list">
                                    <ul>
                                        <ListCom PathLink="/services/house" Title="House & Office Moving" />

                                        <ListCom PathLink="/services/commercialdelivery" Title="Commercial Deliveries" />

                                        <ListCom PathLink="/services/furniture" Title="Furniture Delieveries" />

                                        <ListCom PathLink="/services/b2b" Title="B2B" />

                                        <ListCom PathLink="/services/fragilefreight" Title="Fragile Freight" />

                                        <ListCom PathLink="/services/samedaydelivery" Title="Same Day Delivery" />

                                        <ListCom PathLink="/services/storage" Title="Storage" />

                                        <ListCom PathLink="/services/linehaultruck" Title="Line Haul Truck" />

                                        <ListCom PathLink="/services/packing" Title="Packing Services" />

                                    </ul>
                                </div>
                                <div className="service-details-help">
                                    <div className="help-shape-1"></div>
                                    <div className="help-shape-2"></div>
                                    <h2 className="help-title">Contact with <br /> us for any <br /> advice</h2>
                                    <div className="help-icon">
                                        <span> <MdOutlineLocalPhone /> </span>
                                    </div>
                                    <div className="help-contact">
                                        <p>Need help? Talk Us</p>
                                        <a href="tel:0800366683">0800 366 683</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                        <div className="services-details__content">
                            <img src={MainBanner} alt="" />
                            <h3 className="mt-4">Service Overview</h3>
                            <p>{props.OverviewP1}</p>
                            <p>{props.OverviewP2}</p>
                            <p>{props.OverviewP3}</p>
                            <div className="content mt-40">
                                <div className="text">
                                    <h3>Service Center</h3>
                                    <p>{props.Center1}</p>
                                    <blockquote className="blockquote-one">{props.Qoute1}</blockquote>
                                </div>
                                <div className="feature-list mt-4">
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                                            <img className="mb-3" src={img1} alt="images" />
                                            <p>{props.Para1}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                                            <img className="mb-3" src={img2} alt="images" />
                                            <p>{props.Para2}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="innerpage mt-25">
                                <h3>Frequently Asked Question</h3>
                                <p>Our FAQs cover a range of topics including the services we offer (packing, moving, storage, and delivery), how to track your order, service areas, booking procedures, accepted payment methods, rescheduling or cancellation policies, handling of fragile items, storage unit options, insurance coverage, and customer support contact details, ensuring you have all the necessary information for a seamless experience.</p>
                                <ul className="accordion-box wow fadeInRight">

                                    <AccordComp />

                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <Footer />

        <ToTop />

    </>)

};

export default ServiceDetail;