import React from "react";
import ServiceDetail from "./ServiceDetail";

const B2B = () => {

    return (<>
        <ServiceDetail
            Heading="Business to Business Freight"

            OverviewP1="DAV Transport provides Business to Business stock transfer for many retailer outlets and manufacturers. As we are doing 7-day operations in most of the regions. Once a week we cover every area of north island to provide a prompt and cost-efficient delivery service."

            OverviewP2="Whether you need some things picked up, delivered or even if you need your whole operation moved, we’ve got you covered. We are based in 6 metropolitan areas where we are servicing the north island. Our customers can see the process from pick up the transfer to delivering it to the end customer as we have application and software. Which is updating our customer about the delivery status and showing the proof of delivery status too."

            Para1="DAV Transport believe all our customer requirements are different, that’s why we cater to our customers according to their requirement. DAV transport provides a range of extra services of packing, unpacking, assembly and disassembly. We have a robust process. Once you give us a call, we will start discussing your moving requirements, and we’ll design a personalized moving strategy"

            Para2="After that our skilled team arrives prepared, ensuring your belongings are packed and secured. on Your moving day we carried out your move with precision and keeping you informed throughout the process. At the end of your move, we cautiously place your items in your new house and offer unpacking assistance for seamless transition. "
        />
    </>)

};

export default B2B;