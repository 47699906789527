import React from 'react';
import Form from 'react-bootstrap/Form';
import InputTag from '../components/input';
import SelectTag from "../components/Select";
import RadioTag from '../components/Radio';
import { ParkingValues, StairsValues } from '../utils/constants';
import '../UI-style/Form.css';

function FromAddress(props) {
    const { setFirstName, setLastName, setEmail, setPhoneNum, progressTab, setSuburb, setStreet, setBuilding, setParkingDistance, setStairs, setLiftingPermission, setDataSubmit, firstName, lastName, email, phoneNum, suburb, street, parkingDistance, stairs, building, liftingPermission, mt } = props;
    const handleSubmit = () => {
        setDataSubmit(true)
    }
    return (
        <div>
            <Form className={`d-flex flex-row flex-wrap form-container justify-content-between ${mt}`}>
                {progressTab && (
                    <>
                        <InputTag
                            customClass="w-48 mb-4"
                            customClasses="bg-trans"
                            Placeholder="Your First Name"
                            Type="text"
                            onChange={setFirstName}
                            value={firstName}
                        />
                        <InputTag
                            customClass="w-48 mb-4"
                            customClasses="bg-trans"
                            Placeholder="Your Last Name"
                            Type="text"
                            onChange={setLastName}
                            value={lastName}
                        />
                        <InputTag
                            customClass="w-48 mb-4"
                            customClasses="bg-trans"
                            Placeholder="Your E-Mail"
                            Type="email"
                            onChange={setEmail}
                            value={email}
                        />
                        <InputTag
                            customClass="w-48 mb-4"
                            customClasses="bg-trans"
                            Placeholder="Your Phone Number"
                            Type="tel"
                            onChange={setPhoneNum}
                            value={phoneNum}
                        />
                    </>
                )}
                <InputTag
                    customClass="w-48 mb-4"
                    customClasses="bg-trans"
                    Placeholder="City Name"
                    Type="text"
                    onChange={setSuburb}
                    value={suburb}
                />
                <InputTag
                    Placeholder="Street Name"
                    customClasses="bg-trans"
                    Type="text"
                    onChange={setStreet}
                    customClass="w-48 mb-4"
                    value={street}
                />
                <InputTag
                    customClass="w-100 "
                    customClasses="bg-trans custom-input"
                    Placeholder="Your Building Number"
                    Type="text"
                    onChange={setBuilding}
                    value={building}
                />
                <SelectTag
                    Options={ParkingValues}
                    Label={
                        <Form.Label>
                            <span className='fw-bold'>How close can we park?</span>
                        </Form.Label>
                    }
                    labelClass="w-48"
                    selectClass="bg-trans -auto"
                    Info={"How close can we park our truck to the building's entrance?"}
                    onChange={setParkingDistance}
                    value={parkingDistance}
                />
                <SelectTag
                    Options={StairsValues}
                    Label={
                        <Form.Label>
                            <span className='fw-bold'>Flights of stairs?</span>
                        </Form.Label>
                    }
                    labelClass="w-48 mb-3"
                    selectClass="bg-trans"
                    onChange={setStairs}
                    Info={"How many flights of stairs we need to climb to access the furniture we'll need to move? Stairs affect the working time."}
                    value={stairs}
                />
                <RadioTag
                    Label={
                        <Form.Label>
                            <span className='fw-bold'>Can we use a lift?</span>
                        </Form.Label>
                    }
                    Info={"If you have a lift, can we use it?"}
                    onChange={setLiftingPermission}
                    value={liftingPermission}
                />
                <div className="form-container my-3 custom_button">
                    <div className="animate-3 block theme-btn btn-style-one" onClick={handleSubmit}>
                        <span className="btn-title">Submit</span>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default FromAddress;