import React, { useEffect } from "react";
import Footer from "../UI-Components/Common/Footer";
import bannerBG from "../../src/DAVtranportImage/images/final/fullTeam.webp"
import serv1 from "../DAVtranportImage/images/final/12.webp";
import serv2 from "../DAVtranportImage/Freight-For-Fragile-Goods7-1.webp"
import serv3 from "../DAVtranportImage/images/final/1.webp"
import serv4 from "../DAVtranportImage/images/final/5.webp";
import serv5 from "../DAVtranportImage/Untitled design (2).webp";
import serv6 from "../DAVtranportImage/images/final/8.webp";
import serv8 from "../DAVtranportImage/additional-services-1.webp";
import serv7 from "../DAVtranportImage/Storage.webp";
import track from "../DAVtranportImage/tracking1.webp"
import { BsTruckFront } from "react-icons/bs";
import { TiDropbox } from "react-icons/ti";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { FaBoxes } from "react-icons/fa";
import { FaBoxOpen } from "react-icons/fa6";
import { TbBuildingWarehouse } from "react-icons/tb";
import { GiBoxUnpacking } from "react-icons/gi";
import "../UI-style/Services.css";
import CustomerSupport from "../UI-Components/Common/CustomerSupport"
import ServicesCom from "./Common/ServicesCom";
import { Link } from "react-router-dom";
import { ToTop } from "./Common/ToTop";

const Services = () => {

    useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
    useEffect(() => {
		const prefetchImages = [serv1, serv2, serv3, serv4, serv5, serv6, serv7, serv8, bannerBG, track];

		prefetchImages.forEach((image) => {
			const link = document.createElement("link");
			link.rel = "prefetch";
			link.href = image;
			link.as = "image";
			document.head.appendChild(link);
		});
	}, []);

    return (<>

        <section className="page-title" style={{ backgroundImage: `url(${bannerBG})` }}>
            <div className="auto-container">
                <div className="title-outer text-center">
                    <h1 className="title"><b>Services</b></h1>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </section >

        <section className="services-section">
            <div className="auto-container">
                <div className="outer-box">
                    <div className="service-carousel d-flex flex-wrap">

                        <ServicesCom Image={serv1} Icon={<BsTruckFront />} PathLink="/services/b2b" Title="B2B (Business To  Business freight)" Description="Business to Business stock transfer services for many retailer store in North Island. We DAV transport try to cover every corner in North Island at least once in a week." />

                        <ServicesCom Image={serv2} Icon={<TiDropbox />} PathLink="/services/fragilefreight" Title="Fragile Freight (Handle with Care)" Description="Our fragile freight service ensures the safe and secure transport of your delicate items, with specialized packaging and handling for peace of mind." />

                        <ServicesCom Image={serv3} Icon={<MdOutlineDeliveryDining />} PathLink="/services/samedaydelivery" Title="Same Days Deliveries" Description="DAV transport same-day delivery service guarantees swift and reliable delivery of your items and goods, ensuring they reach at your doorsteps within few hours." />

                        <ServicesCom Image={serv4} Icon={<FaBoxes />} PathLink="/services/house" Title="House & Office Moving" Description="DAV Transport is the house and office moving specialist that locals trust. We offer a stress-free service at affordable price, providing you with a smooth move." />

                        <ServicesCom Image={serv5} Icon={<BsTruckFront />} PathLink="/services/commercialdelivery" Title="Commercial Deliveries" Description="Our Dav Transport does commercial orders for few retailer stores and manufacturer in North Island. And flexible to provide right solution for your commercial orders." />

                        <ServicesCom Image={serv6} Icon={<FaBoxOpen />} PathLink="/services/furniture" Title=" Any Furniture Deliveries" Description="Our furniture moving service offers a stress-free experience with professional handling, secure transportation, and timely delivery to your new location." />

                        <ServicesCom Image={serv7} Icon={<TbBuildingWarehouse />} PathLink="/services/storage" Title="Storage Availability" Description="DAV's our storage service offers secure, climate-controlled units with flexible rental terms, ensuring your belongings are safe and accessible whenever you need them." />

                        <ServicesCom Image={serv8} Icon={<GiBoxUnpacking />} PathLink="/services/packing" Title="Packaging Supplies" Description="Our packing service ensures your items are securely and efficiently packed using high-quality materials, providing peace of mind during your move or shipment." />
                    </div>
                </div>
            </div>
        </section>

        <CustomerSupport Image={track} />

        <Footer />

        <ToTop />
    </>)
}

export default Services;