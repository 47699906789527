import React, { useEffect } from "react";
import "../UI-style/Error.css";
import NotFound from "../DAVtranportImage/images/404.webp"

// wow lib 
import WOW from 'wowjs';
import { Link } from "react-router-dom";

const NotFoundPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        new WOW.WOW().init();
    }, []);

    return (<>
        <section className="error-page">
            <div className="auto-container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="error-page__inner">
                            <div className="error-page__title-box">
                                <img src={NotFound} alt="" />
                                <h3 classNameName="error-page__sub-title">PAGE NOT FOUND!</h3>
                            </div>
                            <p className="error-page__text">Sorry we can't find that page! The page you are looking <br className="hde" /> for was never existed.</p>  
                            <Link to="/" className="theme-btn btn-style-one shop-now"><span className="btn-title">Back to Home</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);

};

export default NotFoundPage;