import React from "react";

const AboutSec = (props) => {
    return (<>
        <div className="about-block col-lg-6 col-md-6 col-sm-6 ">
            <div className="inner-box  wow fadeInRight">
                <i className="icon">{props.Icon}</i>
                <h5 className="title">{props.title}</h5>
                <div className="text">{props.description}</div>
            </div>
        </div>
    </>)
};

export default AboutSec;