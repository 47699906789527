import React from "react";

const SwiperContent = (props) => {

    return (<>
        <div className="swiper-slide">
            <div className="testimonial-block">
                <div className="inner-box">
                    <div className="content">
                        <div className="icon-quote"></div>
                        <div className="text">{props.Review}</div>
                        <h3 className="name">{props.Name}</h3>
                        {/* <span className="designation">Co-Founder</span> */}
                        <figure className="image">
                            <img src={props.Image} alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default SwiperContent;