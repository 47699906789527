import React, { useEffect } from "react";
import bannerBG from "../../src/DAVtranportImage/images/final/14.webp"
import topImage from "../DAVtranportImage/images/final/homeChild.webp"
import Image2 from "../../src/DAVtranportImage/images/final/12.webp";
import CallImage from "../Images/icons/icon-man.webp"
import { TbTruckDelivery } from "react-icons/tb";
import { FaBoxOpen, FaTruck, FaGlassMartini } from "react-icons/fa";
import { IoIosCheckboxOutline } from "react-icons/io";
import { FaBoxes } from "react-icons/fa";
import { MdOutlineWarehouse } from "react-icons/md";
import { GiBoxUnpacking } from "react-icons/gi";
import Footer from "../../src/UI-Components/Common/Footer";
import "../UI-style/About.css"
import CustomerSupport from "../../src/UI-Components/Common/CustomerSupport"
import AboutSec from "./Common/AboutSec";
import AboutCarousel from "./Common/AboutCarousel";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import { Link } from "react-router-dom";

// wow lib 
import WOW from 'wowjs';
import { ToTop } from "./Common/ToTop";

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        new WOW.WOW().init();
    }, []);

    const option = {
        loop: true,
        autoplay: true,
        autoplayHoverPause: true,
        nav: false,
        dots: true,
        responsive: {
            1200: {
                items: 3
            },
            768: {
                items: 2
            },
            0: {
                items: 1
            }
        }
    }

    return (<>

        <section className="page-title" style={{ backgroundImage: `url(${bannerBG})` }}>
            <div className="auto-container">
                <div className="title-outer text-center">
                    <h1 className="title">About Us</h1>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="about-section">
            <div className="auto-container">
                <div className="row">

                    <div className="content-column col-lg-7 col-md-12 col-sm-12 order-lg-2 wow fadeInRight">
                        <div className="inner-column">
                            <div className="sec-title">
                                <span className="sub-title">Leading Furniture moving and Freight service provider. With 6 terminals in major metropolitan areas within North Island.</span>
                                <h2 className="words-slide-up text-split">Leading Operations in the North Island Areas <br className="hde" />
                                    <span className="color">Driving To North Island Weekly</span>
                                </h2>
                                <div className="text">Specialising in furniture moving and freight delivery. DAV Transport covers all areas of North Island from Northland to Wellington Including Auckland, Waikato, Bay of Plenty, Gisborne, Taranaki, Manawatū-Whanganui, Hawke’s Bay.
                                <br />
                                DAV Transport has been in Moving and Freight delivery Business in North Island Since 2015, We can help with all your furniture moving and freight delivery needs and will make sure to move your items with the upmost care. We have a range of trucks to suit all general freight Requirements.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="image-column col-lg-5 col-md-12 col-sm-12 wow fadeInLeft">
                        <div className="inner-column">
                            <div className="image-box">
                                <figure className="image overlay-anim reveal">
                                    <img src={topImage} alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="auto-container">
                <div className="row">
                    <div className="content-column style-two col-lg-7 col-md-12 col-sm-12 order-lg-2 wow fadeInRight"
                        data-wow-delay="300ms">
                        <div className="inner-column">
                            <div className="row wow fadeInRight">

                                <AboutSec Icon={<FaBoxOpen />} title="OUR MISSION" description="DAV TRANSPORT pride itself on meeting customer expectations and keeping customer first. We move your freight and belonging with the utmost care as if they were our own, we are reliable and hardworking and experienced team. " />

                                <AboutSec Icon={<IoIosCheckboxOutline />} title="Learn More" description="Specializing in supply chain solution, house & office moving, general freight delivery, you can trust on DAV Transport For all your worry-free delivery and storage solutions. Choose us for affordable, customer satisfaction and prompt service. " />

                            </div>
                            {/* <div className="auther-box">
                                <div className="inner-box  wow fadeInRight">
                                    <div className="author-image"><img src={CEO}
                                        alt="Image" /></div>
                                    <div className="author-info">
                                        <h6 className="name">Abhinav</h6>
                                        <span className="designation">CEO, DAV Transport</span>
                                    </div>
                                </div>
                                <div className="sign"><img src={Sign} alt="Image" /></div>
                            </div> */}
                        </div>
                    </div>

                    <div className="image-column style-two col-lg-5 col-md-12 col-sm-12 align_center_992" >
                        <div className="inner-column  wow fadeInLeft" data-wow-delay="300ms">
                            <div className="image-box">
                                <figure className="image overlay-anim reveal">
                                    <img src={Image2} alt="" />
                                    </figure>
                                <div className="exp-box bounce-y">
                                    <i><TbTruckDelivery /></i>
                                    <h3 className="title">612 k</h3>
                                    <span>Delivered Goods</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="services-section-three pt-0">
            <div className="auto-container">
                <div className="sec-title text-center">
                    <span className="sub-icon"></span>
                    <span className="sub-title">Customer Teams & An Agile Services</span>
                    <h2 className="words-slide-up text-split">Specialist logistics services</h2>
                </div>
                <div className="outer-box">

                    <OwlCarousel className="service-carousel owl-carousel owl-theme default-dots-two" {...option}>

                        <AboutCarousel PathLink="/services/house" Icon={<FaBoxes />} Title="House & Office" Description="Our global logistics expertise, advanced supply chain technology & customized logistics solutions" />

                        <AboutCarousel PathLink="/services/commercialdelivery" Icon={<FaTruck />} Title="Commercial Deliveries" Description="Our global logistics expertise, advanced supply chain technology & customized logistics solutions" />

                        <AboutCarousel PathLink="/services/furniture" Icon={<GiBoxUnpacking />} Title="Furniture Deliveries" Description="Our global logistics expertise, advanced supply chain technology & customized logistics solutions" />

                        <AboutCarousel PathLink="/services/storage" Icon={<MdOutlineWarehouse />} Title="Storage Availability" Description="Our global logistics expertise, advanced supply chain technology & customized logistics solutions" />

                        <AboutCarousel PathLink="/services/packing" Icon={<FaBoxes />} Title="Packing Supplies" Description="Our global logistics expertise, advanced supply chain technology & customized logistics solutions" />

                        <AboutCarousel PathLink="/services/fragilefreight" Icon={<FaGlassMartini />} Title="Fragile Freight" Description="Our global logistics expertise, advanced supply chain technology & customized logistics solutions" />

                    </OwlCarousel>

                </div>
            </div>
        </section>

        {/* <section className="team-section">
            <div className="bg bg-image" style={{ backgroundImage: `url(${bgTeam})` }}></div>
            <div className="auto-container">
                <div className="sec-title text-center">
                    <span className="sub-icon"></span>
                    <span className="sub-title">Experience Team</span>
                    <h2 className="words-slide-up text-split">Meet Our Amazing Team</h2>
                </div>
                <div className="row justify-content-center D_block">

                    <Team Image={teamMember} />

                    <Team Image={teamMember1} />

                    <Team Image={teamMember2} />

                    <Team Image={teamMember3} />

                    <Team Image={teamMember4} />

                    <Team Image={teamMember5} />

                    <Team Image={teamMember6} />

                    <Team Image={teamMember7} />

                    <Team Image={teamMember8} />

                    <Team Image={teamMember9} />

                    <Team Image={teamMember10} />

                    <Team Image={teamMember12} />

                    <Team Image={teamMember13} />

                    <Team Image={teamMember14} />

                </div>
            </div>
        </section> */}

        <CustomerSupport Image={CallImage} />

        <Footer />

        <ToTop />

    </>)
};

export default About;