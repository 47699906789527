import React from "react";
import ButtonReusable from "./ButtonReusable";
import "../../../src/UI-style/Services.css";

const CustomerSupport = (props) => {

    return (<>
        <section className="call-to-action pt-3">
            <div className="icon-arrow"></div>
            <div className="auto-container">
                <div className="outer-box">
                    <div className="bg bg-pattern-1"></div>
                    <div className="content-box">
                        <h2 className="title words-slide-up text-split">24/7 customer support any time of the day or night
                        </h2>
                        <div className="text">customers can get help and find answers to questions as soon</div>
                        <div className="btn-box">

                            <ButtonReusable InnerText="Request a Quote" PathLink="/booking" ClassName="theme-btn btn-style-one" Display="dis-inline" />

                            <ButtonReusable InnerText="Contact Us" PathLink="/contact" ClassName="theme-btn btn-style-one light-bg" Display="dis-inline" />
                        </div>
                    </div>
                    <div className="image-box wow fadeInLeft" data-wow-delay="300ms" >
                        <figure className="image reveal"><img src={props.Image} alt="" /></figure>
                    </div>
                </div>
            </div>
        </section>
    </>)

};

export default CustomerSupport;