import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { RxHamburgerMenu } from "react-icons/rx";
import logoMain from "../../DAVimage/davtransport-logo-CMYK.webp";
import MobileNav from "../../UI-Components/Common/ModalNav"; // Importing the MobileNav component
import "../../../src/UI-style/Header.css";
import { Link, Outlet } from 'react-router-dom';
import ButtonReusable from './ButtonReusable';
import $ from "jquery";
import "../../js/script-Copy";
import { useLocation } from "react-router-dom";
import Header1 from "./Header1"

const Header = () => {

    function headerStyle() {
        if ($('.main-header').length) {
            var windowpos = $(window).scrollTop();
            var siteHeader = $('.header-style-one');
            var scrollLink = $('.scroll-to-top');
            var sticky_header = $('.main-header .sticky-header');

            if (windowpos > 100) {
                sticky_header.addClass("fixed-header animated slideInDown");
                scrollLink.fadeIn(300);
            } else { sticky_header.removeClass("fixed-header animated slideInDown"); scrollLink.fadeOut(300); }

            if (windowpos > 1) {
                siteHeader.addClass("fixed-header");
            }
            else {
                siteHeader.removeClass("fixed-header");
            }
        }
    }

    $(window).on('scroll', function () { headerStyle() });

    const path = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const openMobileNav = () => {
        setIsOpen(!isOpen);
    };

    const closeMobileNav = () => {
        setIsOpen(false);
    };

    return (
        <>
            {path.pathname === "/" ?
                <>
                    <header className="main-header header-style-one">
                        <div className="header-top">
                            <div className="auto-container">
                                <div className="inner-container">
                                    <div className="top-left">
                                        <ul className="list-style-one light">
                                            <li><i><FontAwesomeIcon icon={faPhone} /></i> <a href="tel:0800366683">0800 366 683</a></li>
                                            <li><i><FontAwesomeIcon icon={faEnvelope} /></i> <a href="mailto: davtransport.ltd@gmail.com"><span className="__cf_email__" data-cfemail="e69395839488878b83a682898b878f88c885898b">davtransport.ltd@gmail.com</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="top-right">
                                        <ul className="social-icon-six">
                                            <li>
                                                <a href="https://www.davtransport.co.nz/freight/login/" target='_blank' rel='noreferrer' className="title">Login</a>
                                            </li>
                                            <li>/</li>
                                            <li>
                                                <a href="https://www.davtransport.co.nz/freight/signup/" target='_blank' rel='noreferrer' className="title">Sign-up</a>
                                            </li>
                                            <li><a href="https://www.facebook.com/Davtransportremovals/"><i><FaFacebookF /></i></a></li>
                                            <li><a href="https://www.instagram.com/davtransportnz/?igsh=MWg0dzJ1ZDBqcjZ3Nw%3D%3D"><i><FaInstagram /></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header-lower">
                            <div className="auto-container">
                                <div className="main-box">
                                    <div className="logo-box">
                                        <div className="logo"><Link to="/"><img className="logoImg" src={logoMain} alt="Logo" title="DAV Transport" /></Link></div>
                                    </div>
                                    <div className="nav-outer">
                                        <nav className="nav main-menu">
                                            <ul className="navigation">
                                                <li className="current"><Link to="/">Home</Link></li>
                                                <li className="dropdown mr_2"><Link to="/services">Services</Link>
                                                    <ul>
                                                        <li className="dropdown"><Link to="/services/house">House & Office Moving</Link>
                                                            <ul>
                                                                <li><Link to="/services/house">Retirement Home</Link></li>
                                                                <li><Link to="/services/house">Apartment</Link></li>
                                                                <li><Link to="/services/house">Flat</Link></li>
                                                                <li><Link to="/services/house">Storage</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li className="dropdown"><Link to="/services/commercialdelivery">Commercial Deliveries</Link>
                                                            <ul>
                                                                <li><Link to="/services/commercialdelivery">Bulk Run Deliveries</Link></li>
                                                                <li><Link to="/services/commercialdelivery">Office Movers</Link></li>
                                                                <li><Link to="/services/commercialdelivery">Business Relocation</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li className="dropdown"><Link to="/services/packing">Packing Supplies</Link>
                                                            <ul>
                                                                <li><Link to="/services/packing">Boxes (different Sizes)</Link></li>
                                                                <li><Link to="/services/packing">Moving Blanket</Link></li>
                                                                <li><Link to="/services/packing">Bubblewrap</Link></li>
                                                                <li><Link to="/services/packing">Shrinkwrap</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li><Link to="/services/furniture">Furniture & Whiteware Deliveries</Link></li>
                                                        <li><Link to="/services/b2b">B2B (Business To Business freight)</Link></li>
                                                        <li><Link to="/services/fragilefreight">Fragile Freight</Link></li>
                                                        <li><Link to="/services/samedaydelivery">Same Day Deliveries</Link></li>
                                                        <li><Link to="/services/storage">Storage</Link></li>
                                                        <li><Link to="/services/linehaultruck">Line Haul Truck (Cover whole North Island)</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/mymove">My Move</Link></li>
                                                <li>      
                                                    <a href="https://www.davtransport.co.nz/freight/" target="_blank" rel="noopener noreferrer"> General Freight </a>
                                                </li>
                                                <li><Link to="/about">About Us</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="outer-box">
                                        <div className="btn-box">

                                            <ButtonReusable InnerText="Booking" PathLink="/booking" ClassName="theme-btn btn-style-one" />

                                        </div>
                                        <div className="mobile-nav-toggler" onClick={openMobileNav}><span className="icon"><RxHamburgerMenu /></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MobileNav isOpen={isOpen} onClose={closeMobileNav} />
                        <div className="sticky-header">
                            <div className="auto-container">
                                <div className="inner-container">
                                    <div className="logo">
                                        <Link to="/" title="DAV Transport"><img src={logoMain} alt="" /></Link>
                                    </div>
                                    <div className="nav-outer">
                                        <nav className="main-menu">
                                            <div className="navbar-collapse show collapse clearfix">
                                                <ul className="navigation clearfix">
                                                    <li className='current'><Link to="/">Home</Link></li>
                                                    <li className="dropdown"><Link to="/services">Services</Link>
                                                        <ul>
                                                            <li className="dropdown"><Link to="/services/house">House & Office Moving</Link>
                                                                <ul>
                                                                    <li><Link to="/services/house">Retirement Home</Link></li>
                                                                    <li><Link to="/services/house">Apartment</Link></li>
                                                                    <li><Link to="/services/house">Flat</Link></li>
                                                                    <li><Link to="/services/house">Storage</Link></li>
                                                                </ul>
                                                            </li>
                                                            <li className="dropdown"><Link to="/services/commercialdelivery">Commercial Deliveries</Link>
                                                                <ul>
                                                                    <li><Link to="/services/commercialdelivery">Bulk Run Deliveries</Link></li>
                                                                    <li><Link to="/services/commercialdelivery">Office Movers</Link></li>
                                                                    <li><Link to="/services/commercialdelivery">Business Relocation</Link></li>
                                                                </ul>
                                                            </li>
                                                            <li className="dropdown"><Link to="/services/packing">Packing Supplies</Link>
                                                                <ul>
                                                                    <li><Link to="/services/packing">Boxes (different Sizes)</Link></li>
                                                                    <li><Link to="/services/packing">Moving Blanket</Link></li>
                                                                    <li><Link to="/services/packing">Bubblewrap</Link></li>
                                                                    <li><Link to="/services/packing">Shrinkwrap</Link></li>
                                                                </ul>
                                                            </li>
                                                            <li><Link to="/services/furniture">Furniture & Whiteware Deliveries</Link></li>
                                                            <li><Link to="/services/b2b">B2B (Business To Business freight)</Link></li>
                                                            <li><Link to="/services/fragilefreight">Fragile Freight</Link></li>
                                                            <li><Link to="/services/samedaydelivery">Same Day Deliveries</Link></li>
                                                            <li><Link to="/services/storage">Storage</Link></li>
                                                            <li><Link to="/services/linehaultruck">Line Haul Truck (Cover whole North Island)</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li><Link to="/mymove">My Move</Link></li>
                                                    <li><a href="https://www.davtransport.co.nz/freight/" target="_blank" rel="noopener noreferrer"> General Freight </a></li>
                                                    <li><Link to="/about">About Us</Link></li>
                                                    <li><Link to="/contact">Contact</Link></li>
                                                </ul>
                                            </div>
                                        </nav>
                                        <div className="mobile-nav-toggler" onClick={openMobileNav}><span className="icon"><RxHamburgerMenu /></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <Outlet />
                </>
                : <Header1 />
            }
        </>
    );
};

export default Header;