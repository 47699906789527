var THEMEMASCOT = {};
(function ($) {
    "use strict"; 
    THEMEMASCOT.isRTL = { check: function () { 
        if ($("html").attr("dir") === "rtl") { 
            return true; 
        } 
        else { 
            return false; 
        } 
    } }; 
    THEMEMASCOT.isLTR = { check: function () { 
        if ($("html").attr("dir") !== "rtl") { 
            return true; 
        } 
        else { 
            return false; 
        } 
    } }; 
    function handlePreloader() { 
        if ($('.preloader').length) { 
            $('.preloader').delay(200).fadeOut(500); 
        } 
    }
    function headerStyle() {
        if ($('.main-header').length) {
            var windowpos = $(window).scrollTop(); 
            var siteHeader = $('.header-style-one'); 
            var scrollLink = $('.scroll-to-top'); 
            var sticky_header = $('.main-header .sticky-header'); 
            if (windowpos > 100) { 
                sticky_header.addClass("fixed-header animated slideInDown"); 
                scrollLink.fadeIn(300); 
            } 
            else { 
                sticky_header.removeClass("fixed-header animated slideInDown"); 
                scrollLink.fadeOut(300); 
            }
            if (windowpos > 1) { 
                siteHeader.addClass("fixed-header"); 
            } 
            else { 
                siteHeader.removeClass("fixed-header");
            }
        }
    }
    headerStyle();

    if ($('.main-header li.dropdown ul').length) {
        $('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><i class="fa fa-angle-down"></i></div>');
    }

    var $onepage_nav = $('.onepage-nav');
    var $sections = $('section');
    var $window = $(window);
    function TM_activateMenuItemOnReach() {
        if ($onepage_nav.length > 0) {
            var cur_pos = $window.scrollTop() + 2; 
            var nav_height = $onepage_nav.outerHeight(); 
            $sections.each(function () {
                var top = $(this).offset().top - nav_height - 80, bottom = top + $(this).outerHeight(); 
                if (cur_pos >= top && cur_pos <= bottom) { 
                    $onepage_nav.find('a').parent().removeClass('current').removeClass('active'); 
                    $sections.removeClass('current').removeClass('active'); 
                    $onepage_nav.find('a[href="#' + $(this).attr('id') + '"]').parent().addClass('current').addClass('active'); 
                }
                if (cur_pos <= nav_height && cur_pos >= 0) { 
                    $onepage_nav.find('a').parent().removeClass('current').removeClass('active'); 
                    $onepage_nav.find('a[href="#header"]').parent().addClass('current').addClass('active'); 
                }
            });
        }
    }

    if ($('.scroll-to-target').length) {
        $(".scroll-to-target").on('click', function () {
            var target = $(this).attr('data-target');
            $('html, body').animate({ scrollTop: $(target).offset().top }, 0);
        });
    }

    $(window).on('scroll', function () { 
        headerStyle(); 
        TM_activateMenuItemOnReach(); 
    }); 
    $(window).on('load', function () { 
        handlePreloader(); 
    });
})(window.jQuery);