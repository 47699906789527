import React from "react";
import ServiceDetail from "./ServiceDetail";

const Packing = () => {

    return (<>
        <ServiceDetail
            Heading="Packing Services"

            OverviewP1="DAV Transport offers a range of packaging supplies to help you to complete your house and commercial move. We can provide you from packing tape to packing boxes. We can tailor our services according to your requirements."

            OverviewP2="We know every house move is not the same so you can let us know how we can help you. We are here to listen to your requirement and work accordingly. If you don’t want packaging supplies from us. we still guide you to give you an information about quantity of packaging supplies you need. "

            // Para1="There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable.There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable."

            // Para2="There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable."
        />
    </>)

};

export default Packing;