import React from 'react';
import Form from 'react-bootstrap/Form';
import '../UI-style/Form.css';

function RadioTag(props) {
  const { Label, Info, onChange, value } = props;
  return (
    <Form.Group className="mb-3">
      {Label}
      {['radio'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="Yes"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
            className='radio-input'
            onChange={(e) => onChange("Yes")}
            checked={value === "Yes"}
          />
          <Form.Check
            inline
            label="No"
            name="group1"
            type={type}
            className='radio-input'
            id={`inline-${type}-2`}
            onChange={(e) => onChange("No")}
            checked={value === "No"}
          />
        </div>
      ))}
      <span className='info-text'>{Info}</span>
    </Form.Group>
  )
}

export default RadioTag;