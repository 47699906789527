import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const MainCarousel = (props) => {
    useEffect(() => {
        // Prefetch the background images
        const prefetchImages = (src) => {
            const link = document.createElement('link');
            link.rel = 'prefetch';
            link.href = src;
            document.head.appendChild(link);
        };

        prefetchImages(props.Bg);
        prefetchImages(props.shape1);
        prefetchImages(props.shape2);

    }, [props.Bg, props.shape1, props.shape2]); // Run effect when these props change

    return (
        <>
            <div className="slide-item">
                <div className="bg bg-image" style={{ backgroundImage: `url(${props.Bg})` }}></div>
                <div className="bg-shape-1" style={{ backgroundImage: `url(${props.shape1})` }}></div>
                <div className="bg-shape-2" style={{ backgroundImage: `url(${props.shape2})` }}></div>
                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-xl-6 col-lg-12">
                            <div className="inner-column">
                                <div className="content-box">
                                    <h1 className="title animate-1">{props.Heading}</h1>
                                    <div className="text animate-2">{props.Para}</div>
                                    <div className="btn-box animate-3">
                                        <Link to="/services" className="theme-btn btn-style-one">
                                            <span className="btn-title">Explore Services</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-column col-lg-6">
                            <div className="inner-column">
                                <figure className="image animate-2">
                                    <img loading="lazy" src={props.FrontImage} alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainCarousel;
