import React, { useState } from 'react';

const JsButton = ({ onClick, children, loadingText, ClassName, Type, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    setIsLoading(true);
    if (onClick) {
      await onClick(e);
    }
    setIsLoading(false);
  };

  return (
    <button type={Type} className={`theme-btn btn-style-one ${ClassName}`} onClick={handleClick} {...props} >
      {(isLoading) ? "loadingText" : <span className="btn-title">{children}</span>}
    </button>
  );
};

export default JsButton;