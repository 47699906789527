import React from "react";

// ACCORDION
import "bootstrap/dist/css/bootstrap.min.css"
import { Accordion } from "react-bootstrap";

// IMAGE
import img1 from "../../src/DAVtranportImage/images/final/small truck back.webp";
import img11 from "../DAVtranportImage/images/final/small truck side.webp";
import img2 from "../DAVtranportImage/images/final/mediumtruckback.webp"
import img21 from "../../src/DAVtranportImage/images/final/mediumtruckside.webp";
import img3 from "../../src/DAVtranportImage/images//final/largetruckback.webp";
import img31 from "../DAVtranportImage/images/final/largetruckside.webp"
import img4 from "../DAVtranportImage/images/final/largetruckback.webp";
import img41 from "../../src/DAVtranportImage/images/final/largetruckside.webp";
import img5 from "../../src/DAVtranportImage/images/final/extraextralargeback.webp";
import img51 from "../../src/DAVtranportImage/images/final/Extraextralargeside.webp";

const AccordComp = () => {

    return (<>

        <Accordion className="block" defaultActiveKey="0" >

            <Accordion.Item eventKey="0" className="bg_grey">
                <Accordion.Header className="acc-btn ms-3 py-4">
                    Small Truck (S)
                </Accordion.Header>
                <Accordion.Body>
                    {/* <div className="text-center">
                        <img className="mb-3 cus_height" src={img1} alt="images" />
                    </div>
                    <div>
                        <img className="mb-3" src={img11} alt="images" />
                    </div> */}
                    <h4 className="m-0"><b>Loading Capacity: Approx 22 cubic metre truck.</b></h4>
                    <h5 className="mt-3"><b>1 Movers (Starts from: $80 p/hr)</b></h5>
                    <h6 className="mt-3"> Fully insured, no cost.</h6>
                    <h6 className="mt-3"> Best trained movers.</h6>
                    <h6 className="mt-3">Our Small trucks can fit a student apartment, small office, one bedroom apartment.</h6>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3 bg_grey" eventKey="1">
                <Accordion.Header className="acc-btn py-4 ms-3">
                    Medium Truck (M)
                </Accordion.Header>
                <Accordion.Body>
                    {/* <div className="text-center">
                        <img className="mb-3 cus_height" src={img2} alt="images" />
                    </div>
                    <div>
                        <img className="mb-3" src={img21} alt="images" />
                    </div> */}
                    <h4 className="m-0"><b>Loading Capacity : Approx 27 cubic meter truck.</b></h4>
                    <h5 className="mt-3"><b>2 Movers ( Starts from: $110 p/hr )</b></h5>
                    <h6 className="mt-3"> Fully insured, no cost.</h6>
                    <h6 className="mt-3"> Best trained movers.</h6>
                    <h6 className="mt-3">Our Medium truck can handle a two bedroom house.</h6>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3 bg_grey" eventKey="2">
                <Accordion.Header className="acc-btn py-4 ms-3">
                    Large Truck (L)
                </Accordion.Header>
                <Accordion.Body>
                    {/* <div className="text-center">
                        <img className="mb-3 cus_height" src={img3} alt="images" />
                    </div>
                    <div>
                        <img className="mb-3" src={img31} alt="images" />
                    </div> */}
                    <h4 className="m-0"><b>Loading Capacity : Approx 32 cubic meter.</b></h4>
                    <h5 className="mt-3"><b>2 Movers (Starts from: $125 p/hr)</b></h5>
                    <h6 className="mt-3"> Fully insured, no cost.</h6>
                    <h6 className="mt-3"> Best trained movers.</h6>
                    <h6 className="mt-3">Our Large truck can fit a 2-3 bedroom house, medium sized office. </h6>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3 bg_grey" eventKey="3">
                <Accordion.Header className="acc-btn py-4 ms-3">
                    Xtra Large Truck (XL)
                </Accordion.Header>
                <Accordion.Body>
                    {/* <div className="text-center">
                        <img className="mb-3 cus_height" src={img4} alt="images" />
                    </div>
                    <div>
                        <img className="mb-3" src={img41} alt="images" />
                    </div> */}
                    <h4 className="m-0"><b>2 Movers (Starts from: $145 p/hr)</b></h4>
                    <h5 className="mt-3">Loading Capacity : Approx 40 cubic meter truck.</h5>
                    <h6 className="mt-3"> Fully insured, no cost.</h6>
                    <h6 className="mt-3"> Best trained movers.</h6>
                    <h6 className="mt-3">Our Extra Large truck can mange 3-4 bedroom house.</h6>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="mt-3 bg_grey" eventKey="4">
                <Accordion.Header className="acc-btn py-4 ms-3">
                    Large-Capacity Truck (XXL)
                </Accordion.Header>
                <Accordion.Body>
                    {/* <div className="text-center">
                        <img className="mb-3 cus_height" src={img5} alt="images" />
                    </div>
                    <div>
                        <img className="mb-3" src={img51} alt="images" />
                    </div> */}
                    <h4 className="m-0"><b>2 Movers (Starts from: $245 p/hr)</b></h4>
                    <h5 className="mt-3"> Fully insured, no hidden cost.</h5>
                    <h6 className="mt-3"> Best trained movers.</h6>
                    <h6 className="mt-3">Loading Capacity : Approx 65 cubic meter truck.</h6>
                    <h6 className="mt-3">Our Extra Extra Large Truck can fit a full family home, large office.</h6>
                </Accordion.Body>
            </Accordion.Item>

        </Accordion >

    </>)

};

export default AccordComp;