import React from 'react';
import Form from 'react-bootstrap/Form';

export default function InputTag(props) {
  const { Label, Placeholder, Type, onChange, customClasses, customClass, value } = props;
  return (
    <Form.Group className={customClass}>
      {Label}
      <Form.Control type={Type} className={`input-name focus-ring focus-ring-light ${customClasses}`} placeholder={Placeholder} onChange={(e) => onChange(e.target.value)} value={value} />
    </Form.Group>
  )
}
