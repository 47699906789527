import { useState, useEffect } from "react";
import "../../js/script-Copy";

export const ToTop = () => {
    const [targets, setTargets] = useState({});

    useEffect(() => {
        const targetsArray = Array.from(document.querySelectorAll('.scroll-to-target'));

        targetsArray.forEach((target) => {
            target.addEventListener('click', (event) => {
                event.preventDefault();
                const targetId = target.getAttribute('data-target');
                const targetElement = document.querySelector(targetId);
                window.scrollTo({ top: targetElement.offsetTop, behavior: 'smooth' });
            });
        });

        setTargets(targetsArray.reduce((acc, target) => ({ ...acc, [target.getAttribute('data-target')]: target }), {}));

    }, []);
    
    return (<>
        <div className="scroll-to-top scroll-to-target" data-target="html">
            <span className="fa fa-angle-up"></span>
        </div>
    </>);
};