import React from "react";
import { Link } from "react-router-dom";

const Clients = (props) => {

    return (<>
        <li className="client-block">
            <Link className="image">
                <img src={props.Logo} alt="" />
            </Link>
        </li>
    </>)

};

export default Clients;