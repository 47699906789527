
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"

// FILES
import Footer from "./Common/Footer";
import AccordComp from "../../src/UI-Components/Common/Accordion";
import ListCom from "../../src/UI-Components/Common/ListCom";
import "../../src/UI-style/Accord.css";
import "../../src/UI-style/ServiceDetail.css";
import MyMoveAccord from "../UI-Components/MyMoveAccord"

// ICON
import { MdOutlineLocalPhone } from "react-icons/md";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { TbTruckDelivery } from "react-icons/tb";
import { SlCalender } from "react-icons/sl";
import { MdPayments } from "react-icons/md";

// Image
import bg from "../DAVtranportImage/images/final/13.webp"
import MainBanner from "../DAVtranportImage/images/final/4.webp"
import { Link } from "react-router-dom";
import { ToTop } from "./Common/ToTop";

const MyMove = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
		const prefetchImages = [ bg, MainBanner];

		prefetchImages.forEach((image) => {
			const link = document.createElement("link");
			link.rel = "prefetch";
			link.href = image;
			link.as = "image";
			document.head.appendChild(link);
		});
	}, []);

    return (<>

        <section className="page-title" style={{ backgroundImage: `url(${bg})` }}>
            <div className="auto-container">
                <div className="title-outer text-center">
                    <h1 className="title"><b>My Move</b></h1>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>My Move</li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="services-details">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4">
                        <div className="service-sidebar">
                            <div className="sidebar-widget service-sidebar-single">
                                <div className="sidebar-service-list">
                                    <ul>
                                        <ListCom PathLink="/services/house" Title="House & Office Moving" />

                                        <ListCom PathLink="/services/commercialdelivery" Title="Commercial Deliveries" />

                                        <ListCom PathLink="/services/furniture" Title="Furniture Delieveries" />

                                        <ListCom PathLink="/services/b2b" Title="B2B" />

                                        <ListCom PathLink="/services/fragilefreight" Title="Fragile Freight" />

                                        <ListCom PathLink="/services/samedaydelivery" Title="Same Day Delivery" />

                                        <ListCom PathLink="/services/storage" Title="Storage" />

                                        <ListCom PathLink="/services/linehaultruck" Title="Line Haul Truck" />

                                        <ListCom PathLink="/services/packing" Title="Packing Services" />

                                    </ul>
                                </div>
                                <div className="service-details-help">
                                    <div className="help-shape-1"></div>
                                    <div className="help-shape-2"></div>
                                    <h2 className="help-title">Contact with <br /> us for any <br /> advice</h2>
                                    <div className="help-icon">
                                        <span> <MdOutlineLocalPhone /> </span>
                                    </div>
                                    <div className="help-contact">
                                        <p>Need help? Talk Us</p>
                                        <a href="tel:0800366683">0800 366 683</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="contact-details__right">
                                <ul className="list-unstyled contact-details__info">
                                    <li>
                                        <div className="icon bg_none">
                                            <span className="colr_blk">
                                                <TfiHeadphoneAlt />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span>7 Day Customer Support</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon bg_none">
                                            <span className="colr_blk">
                                                <TbTruckDelivery />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span>Professional Trained Movers</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon bg_none">
                                            <span className="colr_blk">
                                                <SlCalender />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span>Book a time that Suits you</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon bg_none width_cus132px">
                                            <span className="colr_blk">
                                                <MdPayments />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span>Regular Communication and Easy Payment.</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                        <div className="services-details__content">
                            <img src={MainBanner} alt="" />
                            <h3 className="mt-4">Service Overview</h3>
                            <p> My Move is a service designed to simplify the process of choosing the right truck for your moving needs by providing detailed information. It helps users select a truck that best fits their belongings, ensuring an efficient and organized move.</p>
                            <p>My Move allows users to match their specific requirements with the most suitable vehicle, reducing the risk of having either too much or too little space for their items. We charge one off travel cost as well that cost is the time our mover spending to reaching you and coming back to our depot from completing your move. We estimated that cost according to the distance from city to your place.</p>
                            <div className="content mt-40">
                                <div className="text">
                                    <h3>Truck Dimensions</h3>
                                </div>

                                <MyMoveAccord />

                            </div>

                            {/* <div className="col-xl-5 col-lg-6">
                                <div className="contact-details__right">
                                    <ul className="list-unstyled contact-details__info">
                                        <li>
                                            <div className="icon">
                                                <span>
                                                    <TfiHeadphoneAlt />
                                                </span>
                                            </div>
                                            <div className="text">
                                                <span>7 Day Customer Support</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span>
                                                    <TbTruckDelivery />
                                                </span>
                                            </div>
                                            <div className="text">
                                                <span>Professional Trained Movers</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span>
                                                    <SlCalender />
                                                </span>
                                            </div>
                                            <div className="text">
                                                <span>Book a time that Suits you</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon width_cus132px">
                                                <span>
                                                    <RiSecurePaymentLine />
                                                </span>
                                            </div>
                                            <div className="text">
                                                <span>Regular Communication and Easy Payment.</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                            {/* <hr /> */}

                            <div className="innerpage mt-25">
                                <h3>Frequently Asked Question</h3>
                                <p>Our FAQs cover a range of topics including the services we offer (packing, moving, storage, and delivery), how to track your order, service areas, booking procedures, accepted payment methods, rescheduling or cancellation policies, handling of fragile items, storage unit options, insurance coverage, and customer support contact details, ensuring you have all the necessary information for a seamless experience.</p>
                                <ul className="accordion-box wow fadeInRight">

                                    <AccordComp/>

                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <Footer />

        <ToTop />

    </>)

};

export default MyMove;