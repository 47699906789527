import React from "react";
import ServiceDetail from "./ServiceDetail";

const Storage = () => {

    return (<>
        <ServiceDetail
            OverviewP1="SHORT-TERM AND LONG-TERM STORAGE IN AUCLAND AND NORTHLAND"

            OverviewP2="Moving and settling into a new home has never been easier. It’s very frustrating If you don’t want to deal with the hassle of packing and unpacking your stuff, let us assist you. Our packing service ensures that everything reaches its destination, intact and undamaged. We have a range of boxes and different types of packaging to make sure your household stuff or delivery goods stay safe. We provide a different range of packaging supplies as well to buy. It totally depends on you if you want us to provide you or we can guide you to what type of boxes and how many boxes plus another stuff you need to complete your house move so you can arrange that by yourself."

            OverviewP3="Are you facing an unexpected delay in your move-in date? Have you downsized and now have extra belongings you’re not ready to part with? Are you listing your home for sell and need a space to store things until the sold sign goes up? There are way more reasons why you might need storage but don’t worry we are here to help you to find the storage according to your requirement let’s give us a call to discuss short-term and long-term storage options in Northland and Auckland region. We are just one phone call away."

            Heading="Storage Service"

            // Para1="There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable.There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable."

            // Para2="There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable."
        />
    </>)

};

export default Storage;