import React from "react";
import { Link, useLocation } from "react-router-dom";

const ListCom = (props) => {

    const Rendering = () => {

        const Path = useLocation();

        if (Path.pathname === props.PathLink) {
            return "current";
        }

    }

    return (<>
        <li className={Rendering()} >
            <Link to={props.PathLink} className={<Rendering />}>
                <i className="fas fa-angle-right"></i>
                <span>{props.Title}</span>
            </Link>
        </li>
    </>)

};

export default ListCom;