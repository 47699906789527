import React from "react";
import { Link, Outlet } from "react-router-dom";

// ICON
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

// Photo
import logoFFF from "../../DAVimage/logoFFF.webp";

// CSS
import "../../../src/UI-style/Footer.css";

// React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';

const Footer = () => {

    return (<>
        <footer className="main-footer footer-style-one">
            <div className="bg bg-image bg_full"></div>
            <div className="widgets-section">
                <div className="auto-container">
                    <div className="row">
                        <div className="footer-column col-xl-3 col-sm-6">
                            <div className="footer-widget about-widget">
                                <div className="logo">
                                    <Link to="/"><img src={logoFFF} alt="Logo" /></Link>
                                </div>
                                <div className="text">The Quality of our Service have gained Trust of Our Clients.</div>
                                <strong>Delivering your world one package at a time</strong>
                                <Link to="/booking" className="read-more">Start Booking</Link>
                            </div>
                        </div>
                        <div className="footer-column col-xl-3 col-sm-6">
                            <div className="footer-widget links-widget">
                                <h4 className="widget-title">Explore</h4>
                                <div className="widget-content">
                                    <ul className="user-links">
                                        <li><i><FontAwesomeIcon icon={faAngleRight} /></i> <Link to="/about">About
                                            Company</Link></li>
                                        <li><i><FontAwesomeIcon icon={faAngleRight} /></i> <Link to="/privacy">Privacy Policy</Link></li>
                                        <li><i><FontAwesomeIcon icon={faAngleRight} /></i> <Link to="/contact">Contact Us</Link>
                                        </li>
                                        <li><i><FontAwesomeIcon icon={faAngleRight} /></i> <Link to="/whyus">Why Choose
                                            Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-column col-xl-3 col-sm-6">
                            <div className="footer-widget links-widget two">
                                <h4 className="widget-title">Services</h4>
                                <div className="widget-content">
                                    <ul className="user-links style-two">
                                        <li><Link to="/services/house">House & Office Moving</Link></li>
                                        <li><Link to="/services/commercialdelivery">Commercial Deliveries</Link></li>
                                        <li><Link to="/services/furniture">Furniture Deliveries</Link></li>
                                        <li><Link to="/services/b2b">B2B</Link></li>
                                        <li><Link to="/services/fragilefreight">Fragile Freight</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-column col-xl-3 col-sm-6">
                            <div className="footer-widget links-widget two">
                                <h4 className="widget-title">Services</h4>
                                <div className="widget-content">
                                    <ul className="user-links style-two">
                                        <li><Link to="/mymove">My Move</Link></li>
                                        <li><Link to="/services/samedaydelivery">Same Day Deliveries</Link></li>
                                        <li><Link to="/services/storage">Storage</Link></li>
                                        <li><Link to="/services/linehaultruck">Line Haul Truck</Link></li>
                                        <li><Link to="/services/packing">Packing Supplies</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-contact-info">
                <div className="auto-container">
                    <div className="outer-box">
                        <div className="row">
                            <div className="footer-contact-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
                            data-wow-delay="100ms">
                                <div className="inner-box">
                                    <div className="icon-box"><i><FontAwesomeIcon icon={faPhone} /></i></div>
                                    <div className="content-box">
                                        <span>Phone No</span>
                                        <h6 className="title">0800 366 683</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-contact-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                                data-wow-delay="600ms">
                                <div className="inner-box">
                                    <div className="icon-box"><i><FontAwesomeIcon icon={faMapMarkerAlt} /></i></div>
                                    <div className="content-box">
                                        <span>Whangarei,</span>
                                        <h6 className="title">Auckland, New Plymouth</h6>
                                        <h6 className="title">Hastings/Napier</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-contact-block col-lg-4 col-md-6 col-sm-12  wow fadeInUp"
                                data-wow-delay="800ms">
                                <div className="inner-box">
                                    <div className="icon-box"><i><FontAwesomeIcon icon={faEnvelope} /></i></div>
                                    <div className="content-box">
                                        <span>Email Address:</span>
                                        <h6 className="title">davtransport.ltd@gmail.com</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="copyright-text">© Copyright 2024 by DAV Transport. Created and designed by Vidya
                            Corporation.</div>
                        <ul className="social-icon-five mb--0">
                            <li><a href="https://www.facebook.com/Davtransportremovals/"><i><FaFacebookF /></i></a></li>
                            <li><a href="https://www.instagram.com/davtransportnz/?igsh=MWg0dzJ1ZDBqcjZ3Nw%3D%3D"><i><FaInstagram /></i></a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

        <Outlet />

    </>)
}

export default Footer;