import React from "react";
import { Link } from "react-router-dom";

const ServicesCom = (props) => {

    return (<>
        <div className="service-block">
            <div className="inner-box">
                <figure className="image"><img src={props.Image} alt="" />
                </figure>
                <div className="content-box">
                    <i className="icon">{props.Icon}</i>
                    <h4 className={`title ${props.ClassName}`}><Link to={props.PathLink}>{props.Title}</Link>
                    </h4>
                    <div className="text text_16">{props.Description}</div>
                    <Link to={props.PathLink} className="read-more">Read More 
                    <i className="flaticon-arrow-pointing-to-right"></i></Link>
                </div>
            </div>
        </div>
    </>)

}

export default ServicesCom;