import React, { useEffect } from "react";
import BannerImage from "../../src/Images/background/page-title.webp";
import MainBanner from "../../src/DAVtranportImage/images/final/2.webp"
import Footer from "./Common/Footer";
import { Link } from "react-router-dom";
import { ToTop } from "./Common/ToTop";

const WhyUs = () => {

    useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

    return (<>

        <section className="page-title" style={{ backgroundImage: `url(${BannerImage})` }}>
            <div className="auto-container">
                <div className="title-outer text-center">
                    <h1 className="title">Why Us</h1>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Why Us</li>
                    </ul>
                </div>
            </div>
        </section >

        <section className="project-details">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="project-details__top">
                            <div className="project-details__img"> <img src={MainBanner} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-details__content">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="project-details__content-left">
                                <h3 className="mb-4 mt-5">Here to Know About This Company</h3>
                                <p>At DAV Transport, we specialize in providing top-notch furniture moving services for both residential and commercial clients. With years of experience in the industry, we pride ourselves on our expertise in handling a diverse range of furniture, from delicate antiques to heavy office equipment. Our dedicated team of professionals is trained to handle every aspect of your move with precision and care, ensuring that your belongings are transported safely and efficiently. Whether you’re moving to a new home or relocating your business, our customized solutions and state-of-the-art equipment are designed to meet your specific needs and exceed your expectations.</p>
                                <p className="mb-5">We are committed to delivering a seamless moving experience, characterized by our reliability, competitive pricing, and exceptional customer support. Our transparent pricing ensures no hidden fees, while our comprehensive insurance coverage provides peace of mind throughout the process. At DAV Transport, we understand the importance of a smooth transition, and our focus on customer satisfaction is reflected in the positive feedback from our clients. By incorporating eco-friendly practices and maintaining high standards of service, we strive to be a leader in the moving industry, committed to both our customers and the environment.</p>

                                <ul className="list-style-two mb-0">
                                    <li className="d-block ln_ht_28">
                                        <i className="fa fa-check-circle"></i><b className="fs-5">Expertise in Furniture Handling</b>: With years of experience in moving furniture, our team understands the intricacies of handling both delicate home furnishings and robust office equipment. We use specialized techniques and equipment to ensure your items are transported safely and efficiently.
                                    </li>
                                    <li className="d-block ln_ht_28">
                                        <i className="fa fa-check-circle"></i><b className="fs-5">Tailored Solutions for Every Move</b>: Whether you're relocating your home or your office, we offer customized moving solutions that cater to your specific needs. From disassembly and packing to transportation and reassembly, we handle every detail with care.
                                    </li>
                                    <li className="d-block ln_ht_28">
                                        <i className="fa fa-check-circle"></i><b className="fs-5">Highly Trained Professionals</b>: Our movers are not only skilled and experienced but also thoroughly trained in the best practices for handling furniture. We prioritize professionalism and courteous service, ensuring a smooth and stress-free moving experience.
                                    </li>
                                    <li className="d-block ln_ht_28">
                                        <i className="fa fa-check-circle"></i><b className="fs-5">Reliable and Timely Service</b>: We understand that time is of the essence in both residential and commercial moves. Our team is committed to punctuality and will work diligently to complete your move on schedule, minimizing disruption to your daily life or business operations.
                                    </li>
                                    <li className="d-block ln_ht_28">
                                        <i className="fa fa-check-circle"></i><b className="fs-5">Positive Customer Feedback</b>: Don’t just take our word for it—our satisfied customers speak for us. We take pride in our high customer satisfaction ratings and positive reviews, reflecting our commitment to excellence.
                                    </li>
                                    <li className="d-block ln_ht_28">
                                        <i className="fa fa-check-circle"></i><b className="fs-5">State-of-the-Art Equipment</b>: Our fleet is equipped with modern, well-maintained trucks and moving equipment designed to protect your belongings during transit. We also use high-quality packing materials to safeguard your items against damage.
                                    </li>
                                    <li className="d-block ln_ht_28">
                                        <i className="fa fa-check-circle"></i><b className="fs-5    ">Exceptional Customer Support</b>: Our customer support team is available to assist you throughout the moving process. From answering questions to providing updates, we’re here to ensure you have a positive experience from start to finish.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />

        <ToTop />

    </>)
};

export default WhyUs;