import React, { useState } from 'react';
import { FaTimes, FaPhoneAlt, FaEnvelope, FaLocationArrow } from 'react-icons/fa';
import logoMain from "../../DAVimage/davtransport-logo-CMYK.webp";
import "../../../src/UI-style/Header.css";
import { Link } from 'react-router-dom';
import "../../js/script-Copy";

const MobileNav = ({ isOpen, onClose }) => {
    
    const [IsOpenService,setIsOpenService] = useState(false);

    const toggleDropdown = () => {
        setIsOpenService(!IsOpenService);
    }

    return (
        <div className={`mobile-nav ${isOpen ? 'open' : ''}`}>
            <nav className="menu-box">
                <div className="upper-box">
                    <div className="nav-logo"><Link to="/"><img src={logoMain} alt='Logo' /></Link></div>
                    <div className="close-btn" onClick={onClose}><i><FaTimes /></i></div>
                </div>
                <ul className="navigation clearfix">
                    <li><Link to="/" onClick={onClose}>Home</Link></li>
                    <li className="dropdown"><Link to="/services" onClick={onClose}>Services</Link>
                        <ul className={IsOpenService ? 'dis_block' : ''}>
                            <li><Link to="/services/house" onClick={onClose}>House &amp; Office Moving</Link></li>
                            <li><Link to="/services/commercialdelivery" onClick={onClose}>Commercial Deliveries</Link></li>
                            <li><Link to="/services/furniture" onClick={onClose}>Furniture &amp; Whiteware Deliveries</Link></li>
                            <li><Link to="/services/b2b" onClick={onClose}>B2B (Business To Business freight)</Link></li>
                            <li><Link to="/services/fragilefreight" onClick={onClose}>Fragile Freight</Link></li>
                            <li><Link to="/services/samedaydelivery" onClick={onClose}>Same Day Deliveries</Link></li>
                            <li><Link to="/services/storage" onClick={onClose}>Storage</Link></li>
                            <li><Link to="/services/linehaultruck" onClick={onClose}>Line Haul Truck (Cover whole North Island)</Link></li>
                            <li><Link to="/services/packing" onClick={onClose}>Packing Supplies</Link></li>
                        </ul>
                        <div className="dropdown-btn" onClick={toggleDropdown}><i className="fa fa-angle-down"></i></div>
                    </li>
                    <li><Link to="/mymove" onClick={onClose}>My Move</Link></li>
                    <li><a href="https://www.davtransport.co.nz/freight/" target="_blank" rel="noopener noreferrer" onClick={onClose}> General Freight </a></li>
                    <li><Link to="/booking" onClick={onClose}>Booking</Link></li>
                    <li><Link to="/about" onClick={onClose}>About Us</Link></li>
                    <li><Link to="/contact" onClick={onClose}>Contact</Link></li>
                </ul>
                <ul className="contact-list-one">
                    <li>
                        <i className="icon fff"><FaPhoneAlt className='fff' /></i>
                        <span className="title">Call Now</span>
                        <div className="text"><a href="tel:+0800366683">0800 366 683</a></div>
                    </li>
                    <li>
                        <i className="icon fff"><FaEnvelope /></i>
                        <span className="title">Send Email</span>
                        <div className="text"><a
                            href="mailto: davtransport.ltd@gmail.com"><span
                                className="__cf_email__" data-cfemail="355d50594575565a5845545b4c1b565a58">davtransport.ltd@gmail.com</span></a>
                        </div>
                    </li>
                    <li>
                        <i className="icon"><FaLocationArrow /></i>
                        <span className="title">Address</span>
                        <div className="text">Whangarei,
                            <br />
                            Auckland, New Plymouth
                            <br />
                            Hastings/Napier</div>
                    </li>
                </ul>
                <ul className="social-links">
                    <li><a href="https://www.davtransport.co.nz/freight/login/">Log-In</a></li>
                </ul>
                <ul className="social-links mt-0">
                    <li><a href="https://www.davtransport.co.nz/freight/signup/">Sign-Up</a></li>
                </ul>
                <ul className="social-links">
                    <li><a href="https://www.facebook.com/Davtransportremovals/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://www.instagram.com/davtransportnz/?igsh=MWg0dzJ1ZDBqcjZ3Nw%3D%3D"><i className="fab fa-instagram"></i></a></li>
                </ul>
            </nav>
        </div>
    );
};

export default MobileNav;