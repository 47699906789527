export const ParkingValues = [
  {
    label: 'Select'
  }, {
    label: 'At the Door(<10m)',
    value: '<10m'
  }, {
    label: 'On the Street(10-25m)',
    value: '10-25m'
  }, {
    label: 'Down the Street(25-30m)',
    value: '25-30m'
  }, {
    label: 'A bit of walk (50-100m)',
    value: '50-100m'
  }, {
    label: 'A bit of a hike (100m+)',
    value: '100m+'
  }, {
    label: 'It is complicated',
    value: '500m+'
  },
]

export const StairsValues = [
  {
    label: 'Select'
  }, {
    label: 'None',
    value: '0'
  }, {
    label: '1',
    value: '1'
  }, {
    label: '2',
    value: '2'
  }, {
    label: '3',
    value: '3'
  }, {
    label: '4',
    value: '4'
  }, {
    label: '5+',
    value: '5+'
  },
]

export const Cities = [
  {
    "title": "Auckland",
    "value": "Auckland"
  }, 
  {
    "title": "Christchurch",
    "value": "Christchurch"
  }, 
  {
    "title": "Manukau City", 
    "value": "Manukau City"
  }, 
  {
    "title": "Wellington", 
    "value": "Wellington"
  }, 
  {
    "title": "Northcote",
    "value": "Northcote"
  }, 
  {
    "title": "Hamilton",
    "value": "Hamilton"
  }, 
  {
    "title": "Tauranga",
    "value": "Tauranga"
  }, 
  {
    "title": "Lower Hutt",
    "value": "Lower Hutt"
  }, 
  {
    "title": "Dunedin",
    "value": "Dunedin"
  }, 
  {
    "title": "Palmerston North", 
    "value": "Palmerston North"
  }, 
  {
    "title": "Napier",
    "value": "Napier"
  }, 
  {
    "title": "New Plymouth",
    "value": "New Plymouth"
  }, 
  {
    "title": "Porirua",
    "value": "Porirua"
  }, 
  {
    "title": "Rotorua",
    "value": "Rotorua"
  }, 
  {
    "title": "Whangarei",
    "value": "Whangarei"
  }, 
  {
    "title": "Invercargill",
    "value": "Invercargill"
  }, 
  {
    "title": "Nelson",
    "value": "Nelson"
  }, 
  {
    "title": "Upper Hutt",
    "value": "Upper Hutt"
  }, 
  {
    "title": "Gisborne",
    "value": "Gisborne"
  }, 
  {
    "title": "Paraparaumu",
    "value": "Paraparaumu"
  }, 
  {
    "title": "Timaru",
    "value": "Timaru"
  }, 
  {
    "title": "Blenheim",
    "value": "Blenheim"
  }, 
  {
    "title": "Taupo",
    "value": "Taupo"
  }, 
  {
    "title": "Cambridge",
    "value": "Cambridge"
  }, 
  {
    "title": "Feilding",
    "value": "Feilding"
  }, 
  {
    "title": "Levin",
    "value": "Levin"
  }, 
  {
    "title": "Rolleston",
    "value": "Rolleston"
  }, 
  {
    "title": "Whakatane",
    "value": "Whakatane"
  }, 
  {
    "title": "Richmond",
    "value": "Richmond"
  }, 
  {
    "title": "Havelock North",
    "value": "Havelock North"
  }, 
  {
    "title": "Tokoroa",
    "value": "Tokoroa"
  }, 
  {
    "title": "Mosgiel",
    "value": "Mosgiel"
  }, 
  {
    "title": "Te Awamutu",
    "value": "Te Awamutu"
  }, 
  {
    "title": "Waikanae",
    "value": "Waikanae"
  }, 
  {
    "title": "Hawera",
    "value": "Hawera"
  }, 
  {
    "title": "Waiuku",
    "value": "Waiuku"
  }, 
  {
    "title": "Paraparaumu Beach", 
    "value": "Paraparaumu Beach"
  }, 
  {
    "title": "Wanaka",
    "value": "Wanaka"
  }, 
  {
    "title": "Te Puke",
    "value": "Te Puke"
  }, 
  {
    "title": "Greymouth",
    "value": "Greymouth"
  }, 
  {
    "title": "Matamata",
    "value": "Matamata"
  }, 
  {
    "title": "Thames",
    "value": "Thames"
  }, 
  {
    "title": "Kawerau",
    "value": "Kawerau"
  }, 
  {
    "title": "Kerikeri",
    "value": "Kerikeri"
  }, 
  {
    "title": "Waitara",
    "value": "Waitara"
  }, 
  {
    "title": "Ngaruawahia",
    "value": "Ngaruawahia"
  }, 
  {
    "title": "Mount Maunganui",
    "value": "Mount Maunganui"
  }, 
  {
    "title": "Lincoln", 
    "value": "Lincoln"
  }, 
  {
    "title": "Kaitaia", 
    "value": "Kaitaia"
  }, 
  {
    "title": "Stratford",
    "value": "Stratford"
  }, 
  {
    "title": "Alexandra",
    "value": "Alexandra"
  }, 
  {
    "title": "Cromwell",
    "value": "Cromwell"
  }, 
  {
    "title": "Warkworth", 
    "value": "Warkworth"
  }, 
  {
    "title": "Waihi", 
    "value": "Waihi"
  }, 
  {
    "title": "Raumati Beach",
    "value": "Raumati Beach"
  }, 
  {
    "title": "Marton",
    "value": "Marton"
  }, 
  {
    "title": "Whitianga",
    "value": "Whitianga"
  }, 
  {
    "title": "Tuakau",
    "value": "Tuakau"
  }, 
  {
    "title": "Dargaville",
    "value": "Dargaville"
  }, 
  {
    "title": "Katikati",
    "value": "Katikati"
  }, 
  {
    "title": "Westport",
    "value": "Westport"
  }, 
  {
    "title": "Tauwhare",
    "value": "Tauwhare"
  }, 
  {
    "title": "Te Aroha",
    "value": "Te Aroha"
  }, 
  {
    "title": "Kaikohe",
    "value": "Kaikohe"
  }, 
  {
    "title": "Prebbleton",
    "value": "Prebbleton"
  }, 
  {
    "title": "Paeroa",
    "value": "Paeroa"
  }, 
  {
    "title": "Whangamata",
    "value": "Whangamata"
  }, 
  {
    "title": "Balclutha",
    "value": "Balclutha"
  }, 
  {
    "title": "Snells Beach",
    "value": "Snells Beach"
  }, 
  {
    "title": "Turangi",
    "value": "Turangi"
  }, 
  {
    "title": "Raglan",
    "value": "Raglan"
  }, 
  {
    "title": "Foxton",
    "value": "Foxton"
  }, 
  {
    "title": "Darfield",
    "value": "Darfield"
  }, 
  {
    "title": "Ashhurst",
    "value": "Ashhurst"
  }, 
  {
    "title": "Hokitika",
    "value": "Hokitika"
  }, 
  {
    "title": "Helensville",
    "value": "Helensville"
  }, 
  {
    "title": "Woodend", 
    "value": "Woodend"
  }, 
  {
    "title": "Kihikihi",
    "value": "Kihikihi"
  }, 
  {
    "title": "Pahiatua",
    "value": "Pahiatua"
  }, 
  {
    "title": "Wakefield",
    "value": "Wakefield"
  }, 
  {
    "title": "Ruakaka",
    "value": "Ruakaka"
  }, 
  {
    "title": "Winton",
    "value": "Winton"
  }, 
  {
    "title": "Maraetai",
    "value": "Maraetai"
  }, 
  {
    "title": "Te Anau",
    "value": "Te Anau"
  }, 
  {
    "title": "Clive",
    "value": "Clive"
  }, 
  {
    "title": "Oxford",
    "value": "Oxford"
  }, 
  {
    "title": "Pokeno",
    "value": "Pokeno"
  }, 
  {
    "title": "Milton",
    "value": "Milton"
  }, 
  {
    "title": "Waihi Beach",
    "value": "Waihi Beach"
  }, 
  {
    "title": "Brightwater",
    "value": "Brightwater"
  }, 
  {
    "title": "Leeston",
    "value": "Leeston"
  }, 
  {
    "title": "West Melton",
    "value": "West Melton"
  }, 
  {
    "title": "Waitangi",
    "value": "Waitangi"
  }
]