import React, { useEffect } from "react";
import BannerImage from "../../src/DAVtranportImage/images/dav-transport-7.webp"
import Footer from "./Common/Footer";
import { Link } from "react-router-dom";
import FormComponent from "./Common/FormComponent";
import "../UI-style/Booking.css";
import { ToTop } from "./Common/ToTop";

const Booking = () => {

    useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

    return (<>

        <section className="page-title" style={{ backgroundImage: `url(${BannerImage})` }}>
            <div className="auto-container">
                <div className="title-outer text-center">
                    <h1 className="title">Booking</h1>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Booking</li>
                    </ul>
                </div>
            </div>
        </section>


        <section>
            <div className="container pt-70">
                <div className="section-content">
                    <div id="checkout-form">
                        <div className="row mt-30">
                            <div className="col-md-12 col-lg-12">
                                <div className="billing-details">
                                    <h3 className="mb-30">Booking Details</h3>
                                    <FormComponent cus_DisplayClass="d-flex justify-content-center" Width="w-50 mx-auto" cus_Center="text-center mt-40" custom_class="mt_80" padd="cus_padding" marginTop="my_50" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />

        <ToTop />

    </>)
};

export default Booking;