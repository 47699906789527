import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// PAGES
import Index from "./Index";
import Services from "./Services";
import House from "./House";
import CommercialDelivery from "./CommercialDelivery";
import Furniture from "./Furniture";
import B2B from "./B2B";
import FragileFrieght from "./FragileFrieght";
import SameDayDelivery from "./SameDayDelivery";
import Storage from "./Storage";
import LineHaulTruck from "./LineHaulTruck";
import Packing from "./Packing"
import Booking from "./Booking";
import About from "./About";
import Contact from "./Contact";
import Privacy from "./Privacy";
import WhyUs from "./WhyUs";
import Header from "../../src/UI-Components/Common/Header";
import MyMove from "./MyMove";
import NotFoundPage from "./404";


const Routing = () => {

    const location = useLocation();

    // Determine if the header should be shown
    const showHeader = location.pathname !== '/404notfound';

    return (<>
        {/* <Header /> */}
        {showHeader && <Header />}
        <Routes>
            <Route index element={<Index />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/house" element={<House />} />
            <Route path="/services/commercialdelivery" element={<CommercialDelivery />} />
            <Route path="/services/furniture" element={<Furniture />} />
            <Route path="/services/b2b" element={<B2B />} />
            <Route path="/services/fragilefreight" element={<FragileFrieght />} />
            <Route path="/services/samedaydelivery" element={<SameDayDelivery />} />
            <Route path="/services/storage" element={<Storage />} />
            <Route path="/services/linehaultruck" element={<LineHaulTruck />} />
            <Route path="/services/packing" element={<Packing />} />

            <Route path="/mymove" element={<MyMove />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/whyus" element={<WhyUs />} />

            <Route path="/booking" element={<Booking />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    </>);

};

export default Routing;