import React, { useEffect } from "react";
import "../../src/UI-style/Privacy.css"
import BannerImage from "../../src/Images/background/page-title.webp";
import Footer from "./Common/Footer";
import { Link } from "react-router-dom";
import { ToTop } from "./Common/ToTop";

const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (<>

        <section className="page-title" style={{ backgroundImage: `url(${BannerImage})` }}>
            <div className="auto-container">
                <div className="title-outer text-center">
                    <h1 className="title">Privacy Policy</h1>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="project-details">
            <div className="container">
                <div className="project-details__content">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="project-details__content-left">
                                <h3 className="mb-4 mt-5">Privacy Policy</h3>
                                <p><b> <i> This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally Identifiable Information’ is being used online. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website. </i> </b></p>


                                <h5 className="mt-5"><b>Who we are</b></h5>
                                <p>Our registered business name is DAV Transport Ltd.</p>
                                <p className="mb-5">Our website address is: https://davtransport.co.nz/</p>


                                <h5 className="mt-5"><b>What personal data we collect and why we collect it</b></h5>
                                <p className="mb-5">We collect information from you when you complete any of the following: place an order, subscribe to a newsletter or blog, create an account, fill out an enquiry form, use live chat or enter information anywhere else on our site.</p>


                                <h5 className="mt-5"><b><h5 className="mt-5"><b>What personal data we collect and why we collect it</b></h5></b></h5>
                                <ul className="list-style-two mb-0 mt-3">
                                    <li><i className="fa fa-check-circle"></i> To process your transactions</li>
                                    <li><i className="fa fa-check-circle"></i> To ask for ratings and reviews of services or products</li>
                                    <li><i className="fa fa-check-circle"></i> To follow up after correspondence (live chat, email or phone enquiries)</li>
                                </ul>

                                <p className="mt-60">Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to this website, and are required to keep the information confidential. In addition, all information you supply is encrypted via Secure Socket Layer (SSL) technology.</p>

                                <p className="mt-4">Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to this website, and are required to keep the information confidential. In addition, all information you supply is encrypted via Secure Socket Layer (SSL) technology.</p>



                                <h5 className="mt-5"><b>Google Analytics</b></h5>
                                <p className="mb-5">This website uses a tracking code that follows the behaviour of all website users. Google Analytics does not identify individual users or associate your IP address with any other data held by Google. We use reports provided by Google Analytics to help us understand website traffic and webpage usage. Read Google’s privacy policy <a href="https://policies.google.com/privacy">here</a>.</p>



                                <h5 className="mt-5"><b>How long we retain your data</b></h5>
                                <p className="mb-5">We keep contact form entries, analytics records and customer purchase records permanently for as long as this website is live.</p>

                                <p className="mt-4">For users that register on this website (if any), we also store their personal information provided in the user profile. All users can see, edit, or delete their personal information at any time (except you cannot change the username). Website administrators can also see and edit that information.</p>



                                <h5 className="mt-5"><b>What rights you have over your data</b></h5>
                                <p className="mb-5">If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>



                                <h5 className="mt-5"><b>Cookies</b></h5>
                                <p className="mb-5">These are small files that a website transfers to your computer’s hard drive through your web browser (if you allow it to) that enables the website’s systems to recognise your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services.</p>

                                <p className="mt-4">We use cookies to understand and save user’s preferences for future visits.</p>

                                <p className="mt-4">You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since each browser is a little different, look at your browser’s help menu to learn the correct way to modify your cookies. If you turn cookies off, it won’t affect the user’s experience .</p>



                                <h5 className="mt-5"><b>Your contact information</b></h5>
                                <p className="mb-5">If at any time you would like to unsubscribe from receiving future emails, you can call us on 027 273 1300 and we will promptly remove you from all correspondence.</p>


                                <h5 className="mt-5"><b>Contacting us</b></h5>
                                <p className="mb-5">If there are any questions regarding this privacy policy, you may contact us using the information below.</p>

                                <p className="mt-4">DAV Transport Limited</p>
                                <p className="mt-4">027 273 1300</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />

        <ToTop />

    </>)
};

export default Privacy;