import React from "react";
import { Link } from "react-router-dom";

const ButtonReusable = (props) => {

    return (<>
        <div className={`animate-3 mr_5 ${ props.Display }`}>
            <Link to={props.PathLink} className={props.ClassName}><span className="btn-title">{props.InnerText}</span></Link>
        </div>
    </>)

};

export default ButtonReusable;